import { useRxdbCollection } from "../../composables/useRxdbCollection.js";
import { convertMapToArray } from "@/rxdb/utils.js";
const state = {
  draftPin: null,
};

const getters = {
  getDraftPin: (state) => state.draftPin,
};

const actions = {
  async createDraftPin({ getters }, { observationUuid }) {
    const { execOnCollection } = useRxdbCollection("b_i_m_data_pin");
    const pinData = {
      ...getters.getDraftPin,
      isArchived: false,
      offlineCreated: true,
      observation: observationUuid,
    };

    delete pinData["$id"];
    try {
      await execOnCollection((c) => c.upsert(pinData));
    } catch (error) {
      console.error("Error creating pin", error);
    }
  },

  async deletePin(_, { pinUuid }) {
    const { execOnCollection } = useRxdbCollection("b_i_m_data_pin");

    let pinInRxdb = await execOnCollection((c) =>
      c.findByIds([pinUuid]).exec()
    );
    pinInRxdb = convertMapToArray(pinInRxdb);
    pinInRxdb.map((pin) => ({
      ...pin,
      isArchived: true,
      offlineCreated: true,
    }));

    try {
      await execOnCollection((c) => c.bulkUpsert(pinInRxdb));
    } catch (e) {
      console.error("Error deletePin", e);
    }
  },
};

const mutations = {
  SET_DRAFT_PIN: (state, draftPin) => (state.draftPin = draftPin),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
