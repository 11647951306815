import i18n from "@/setup/i18n";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { Role } from "@socotec.io/socio-vue-components";

const state = {};

const getters = {};

const actions = {
  async fetchRoles(_, { filters }) {
    const metadata = {
      filters: JSON.stringify({
        service_id: process.env.VUE_APP_AMOS_SERVICE_ID,
        ...filters,
      }),
    };

    const request =
      new socioGrpcClient.contributors.contributors.RoleListRequest();

    const response =
      await socioGrpcClient.contributors.contributors.RoleControllerPromiseClient.list(
        request,
        metadata
      );

    await Role.insertOrUpdate({
      data: response.toObject().resultsList.map((role) => ({
        ...role,
        name: i18n.t(`contributors.${role.name}`),
      })),
    });
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
