/*
 ** Below are the rules for mapping destination codes to building characteristics.
 ** If more than one characteristic is specified, then if one or other is present
 ** in the building data, the rule is evaluated as true.
 */
export const CT_DESTINATION_CODES_MAPPING = {
  CDT: [{ isErt: true }],
  ERP: [
    { isErp: true, erpCategory: "1ère" },
    { isErp: true, erpCategory: "2ème" },
    { isErp: true, erpCategory: "3ème" },
    { isErp: true, erpCategory: "4ème" },
  ],
  ERP2017: [
    { isErp: true, erpCategory: "1ère" },
    { isErp: true, erpCategory: "2ème" },
    { isErp: true, erpCategory: "3ème" },
    { isErp: true, erpCategory: "4ème" },
  ],
  ERPCT: [
    { erpMainType: "CTS : Chapiteaux, tentes et structures" },
    { erpSecondaryTypeList: "CTS : Chapiteaux, tentes et structures" },
  ],
  ERPEF: [
    { erpMainType: "EF : Établissements flottants" },
    { erpSecondaryTypeList: "EF : Établissements flottants" },
  ],
  ERPGA: [
    { erpMainType: "GA : Gares accessibles au public" },
    { erpSecondaryTypeList: "GA : Gares accessibles au public" },
  ],
  ERPPA: [
    { erpMainType: "PA : Établissements de plein air" },
    { erpSecondaryTypeList: "PA : Établissements de plein air" },
  ],
  ERPRF: [
    { erpMainType: "REF : Refuges de montagne" },
    { erpSecondaryTypeList: "REF : Refuges de montagne" },
  ],
  ERPSG: [
    { erpMainType: "SG : Structures gonflables" },
    { erpSecondaryTypeList: "SG : Structures gonflables" },
  ],
  IGH: [{ isIgh: true }],
  IHM: [{ isIhm: true }],
  PE2017: [{ isErp: true, erpCategory: "5ème" }],
  PE: [{ isErp: true, erpCategory: "5ème" }],
  PS: [
    { erpMainType: "PS : Parcs de stationnement couvert" },
    { erpSecondaryTypeList: "PS : Parcs de stationnement couvert" },
  ],
  HAB: [{ isResidential: true }],
  HAB2016: [{ isResidential: true }],
  HABT: [{ isResidential: true }],
  HABT2016: [{ isResidential: true }],
  ICPE: [{ isIcpe: true }],
};

/*
 ** Same as above for type codes
 */
export const CT_TYPE_CODES_MAPPING = {
  HA1: [{ individualResidentialFamily: "Individuel - 1ère famille" }],
  HA2I: [{ collectifResidentialFamilyList: "Individuel - 2ème famille" }],
  HA2: [{ individualResidentialFamily: "COLLECTIF_2" }],
  HA3A: [{ collectifResidentialFamilyList: "COLLECTIF_3A" }],
  HA3B: [{ collectifResidentialFamilyList: "COLLECTIF_3B" }],
  HA4: [{ collectifResidentialFamilyList: "COLLECTIF_4" }],
  "Logement-Foyer": [{ collectifResidentialFamilyList: "COLLECTIF_LOGEMENT" }],
  GA: [
    { erpMainType: "GA : Gares accessibles au public" },
    { erpSecondaryTypeList: "GA : Gares accessibles au public" },
  ],
  J: [
    {
      erpMainType:
        "J : Structures d’accueil pour personnes âgées et personnes handicapées",
    },
    {
      erpSecondaryTypeList:
        "J : Structures d’accueil pour personnes âgées et personnes handicapées",
    },
  ],
  M: [
    { erpMainType: "M : Magasins de vente, Centres commerciaux" },
    {
      erpSecondaryTypeList: "M : Magasins de vente, Centres commerciaux",
    },
  ],
  N: [
    { erpMainType: "N : Restaurants et débits de boissons" },
    {
      erpSecondaryTypeList: "N : Restaurants et débits de boissons",
    },
  ],
  O: [
    { erpMainType: "O : Hôtels et autres établissements d'hébergement" },
    {
      erpSecondaryTypeList: "O : Hôtels et autres établissements d'hébergement",
    },
  ],
  OA: [
    { erpMainType: "OA : Hôtels-restaurants d’altitude" },
    {
      erpSecondaryTypeList: "OA : Hôtels-restaurants d’altitude",
    },
  ],
  P: [
    { erpMainType: "P : Salles de danse et salles de jeux" },
    { erpSecondaryTypeList: "P : Salles de danse et salles de jeux" },
  ],
  R: [
    {
      erpMainType:
        "R : Établissements d'éveil, d'enseignement, de formation, centres de vacances, centres de loisirs sans hébergement",
    },
    {
      erpSecondaryTypeList:
        "R : Établissements d'éveil, d'enseignement, de formation, centres de vacances, centres de loisirs sans hébergement",
    },
  ],
  S: [
    { erpMainType: "S : Bibliothèques, centres de documentation" },
    { erpSecondaryTypeList: "S : Bibliothèques, centres de documentation" },
  ],
  T: [
    { erpMainType: "T : Salles d’expositions" },
    { erpSecondaryTypeList: "T : Salles d’expositions" },
  ],
  U: [
    { erpMainType: "U : Établissements de soin" },
    { erpSecondaryTypeList: "U : Établissements de soin" },
  ],
  V: [
    { erpMainType: "V : Établissements de culte" },
    { erpSecondaryTypeList: "V : Établissements de culte" },
  ],
  W: [
    { erpMainType: "W : Administrations, banques, bureaux" },
    { erpSecondaryTypeList: "W : Administrations, banques, bureaux" },
  ],
  X: [
    { erpMainType: "X : Établissements sportifs couverts" },
    { erpSecondaryTypeList: "X : Établissements sportifs couverts" },
  ],
  Y: [{ erpMainType: "Y : Musées" }, { erpSecondaryTypeList: "Y : Musées" }],
  CTS: [
    { erpMainType: "CTS : Chapiteaux, tentes et structures" },
    { erpSecondaryTypeList: "CTS : Chapiteaux, tentes et structures" },
  ],
  EF: [
    { erpMainType: "EF : Établissements flottants" },
    { erpSecondaryTypeList: "EF : Établissements flottants" },
  ],

  GHA: [{ ighTypeList: "GHA : immeubles à usage d'habitation" }],
  GHO: [{ ighTypeList: "GHO : immbeubles à usage d'hôtel" }],
  GHR: [{ ighTypeList: "GHR : immeubles à usage d'enseignement" }],
  GHS: [{ ighTypeList: "GHS : immeubles à usage de dépôt d'archives" }],
  GHTC: [{ ighTypeList: "GHTC : immeubles à usage de tour de contrôle" }],
  GHU: [{ ighTypeList: "GHU : immeubles à usage sanitaire" }],
  GHW: [
    {
      ighTypeList:
        "GHW 1 : immeubles à usage de bureaux, entre 28 et 50 mètres",
    },
    {
      ighTypeList:
        "GHW 2 : Immeubles à usage de bureaux, supérieurs à 50 mètres",
    },
  ],
  GHZ: [
    {
      ighTypeList:
        "GHZ : Immeubles à usage principal d'habitation entre 28 mètres et 50 mètres comportant des locaux autres que ceux à usage d'habitation",
    },
  ],
  ITGH: [
    {
      ighTypeList:
        "ITGH : Immeubles de très grande hauteur, dont la hauteur est supérieure à 200 mètres",
    },
  ],
  PSE: [{ isParkingArea: true, isErp: true }],
  PSH: [{ isParkingArea: true, isResidential: true }],
  PSC: [{ isParkingArea: true, isErt: true }],
};
