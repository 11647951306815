import socioGrpcClient from "./socioGrpcClient";

if (
  process.env.VUE_APP_ENABLE_GRPC_WEB_EXTENSION &&
  ["local", "development"].includes(process.env.VUE_APP_SOCIO_GRPC_API_ENV)
) {
  const findClients = (socioGrpcDomain, clients) => {
    Object.entries(socioGrpcDomain).forEach(([k, v]) => {
      if (k.endsWith("ControllerPromiseClient")) {
        clients.push(v);
      } else if (typeof v === "object") {
        findClients(v, clients);
      }
    });
    return clients;
  };
  const clients = [];
  Object.keys(socioGrpcClient.serviceConfigs).forEach((domainName) => {
    findClients(socioGrpcClient[domainName], clients);
  });

  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
  enableDevTools(clients);
}
