import { Action, Periodicity } from "@/models/";
import requestFactory from "../factory";
import { useService } from "@/setup/useService";
import {
  ActionController,
  PeriodicityController,
} from "@socotec.io/socio-grpc-api/connect/services/amos_back/observation_pb";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { cloneDeep } from "lodash";
import { useRxdbCollection } from "@/composables/useRxdbCollection.js";
import { makeSchemaCompliantIgnoringKeys } from "@/rxdb/utils";

const actionClient = useService(ActionController);
const periodicityClient = useService(PeriodicityController);

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const legacyClient = observationApi.ActionControllerPromiseClient;

const state = {};

const getters = {
  getActions: () => {
    return Action.query()
      .where("isArchived", false)
      .orderBy("createdAt", "desc")
      .orderBy("date")
      .withAll()
      .get();
  },
  observationsActionsCount: () => (observationUuids) => {
    return Action.query()
      .withAll()
      .where((action) => {
        return action.observationsList.some((observation) => {
          return observationUuids.includes(observation);
        });
      })
      .count();
  },
  getBudgetedActions: () => {
    return Action.query()
      .where("status", "TODO")
      .has("expensesOverTimeList", ">", 0)
      .withAllRecursive()
      .get();
  },
};
const actions = {
  async updateAction(_, payload) {
    let formatedPayload = cloneDeep(payload);
    const payloadTransformations = {
      periodicity: () =>
        formatedPayload.periodicityData
          ? {
              ...formatedPayload.periodicityData,
              number: parseInt(formatedPayload.periodicityData.number),
            }
          : null,
      priority: () =>
        formatedPayload.priority?.uuid || formatedPayload.priority,
      contributors: () =>
        formatedPayload.contributors?.map((contributor) => ({
          contributorUuid: contributor,
        })) || [],
    };

    Object.keys(payloadTransformations).forEach((key) => {
      if (key in formatedPayload) {
        formatedPayload[key] = payloadTransformations[key]();
      }
    });

    const response = await actionClient.update(formatedPayload);
    await Action.update({
      data: {
        ...response,
        periodicity: response.periodicity?.uuid,
      },
    });

    const { execOnCollection } = useRxdbCollection("action");
    const action = await execOnCollection((c) =>
      c.findOne(formatedPayload.uuid).exec()
    );

    if (action) {
      const updatedAction = makeSchemaCompliantIgnoringKeys(response, [
        "observations",
        "analyticalAxes",
      ]);
      await action.incrementalUpdate({
        $set: {
          ...updatedAction,
          periodicity: updatedAction.periodicity?.uuid,
          contributors: updatedAction.contributors.map(
            (contributor) => contributor.contributorUuid
          ),
        },
      });
    } else {
      console.warn(`No action found with UUID ${formatedPayload.uuid} in RxDB`);
    }

    if (formatedPayload.periodicity) {
      await Periodicity.insertOrUpdate({
        data: response.periodicity,
      });

      const { execOnCollection: periodicityCollection } =
        useRxdbCollection("periodicity");
      await periodicityCollection((c) => c.upsert(response.periodicity));
    }
  },

  async archiveAction(_, actionUuid) {
    try {
      await actionClient.setArchiveValue({
        uuid: actionUuid,
        isArchived: true,
      });
      await Action.delete(actionUuid);
    } catch (error) {
      console.error(error);
    }
  },

  async destroyAction(_, actionUuid) {
    // INFO - NL - 30/06/2022 - Delete the action if no scenario archived (sent in report), otherwise archived it
    if (!Action.query().find(actionUuid)) return;
    try {
      await actionClient.destroy({ uuid: actionUuid });
      await Action.delete(actionUuid);

      const { execOnCollection } = useRxdbCollection("action");
      const action = await execOnCollection((c) =>
        c.findOne(actionUuid).exec()
      );
      if (action) {
        await action.remove();
      } else {
        console.warn(`No action found with UUID ${actionUuid} in RxDB`);
      }
    } catch (error) {
      console.error(error);
    }
  },

  async retrievePeriodicity(_, uuid) {
    try {
      const response = await periodicityClient.retrieve({ uuid });
      await Periodicity.insertOrUpdate({ data: response });
    } catch (error) {
      console.error(error);
    }
  },

  exportActions: requestFactory.actions.exportFactory(
    legacyClient,
    observationApi.ExportRequest,
    "action"
  ),

  exportActionsArbitration: requestFactory.actions.exportFactory(
    legacyClient,
    observationApi.ExportArbitrationRequest,
    "arbitration",
    "exportArbitration"
  ),
};
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
