import { Agency, AgencyGroup } from "@socotec.io/socio-vue-components";
import { utils } from "@socotec.io/socio-vue-components";
import User from "@/models/User";
import { cloneDeep } from "lodash";
import { useRxdbCollection } from "@/composables/useRxdbCollection";
import { useService } from "@/setup/useService";
import { UserController as AmosUserController } from "@socotec.io/socio-grpc-api/connect/services/amos_back/user_pb";
import { UserController } from "@socotec.io/socio-grpc-api/connect/services/user_management/user_pb";

const amosUserClient = useService(AmosUserController);
const userManagementClient = useService(UserController);

const state = {
  userCompany: null,
};

const getters = {
  getCurrentUser: () => {
    return User.query()
      .where("isCurrentUser", true)
      .with("agencyDatas.agencyGroupDatas")
      .first();
  },
  getCurrentUserPreferedLanguage: (state, getters) => {
    return getters.getCurrentUser?.language;
  },
  getUserByUserManagementUuid: () => (usermanagementUuid) => {
    return User.query().where("usermanagementUuid", usermanagementUuid).first();
  },
  getUserCompany: (state) => {
    return state.userCompany;
  },
};

const actions = {
  async initializeCurrentUser({ commit }) {
    const response = await amosUserClient.resolveUser();
    const agency = response.agency;

    try {
      if (agency?.uuid) {
        if (agency.agencyGroup?.uuid) {
          await AgencyGroup.insert({
            data: agency.agencyGroup,
          });
        }
        // Only store agencyGroup relation or empty string
        agency.agencyGroup = agency.agencyGroup?.uuid || "";
        await Agency.insert({
          data: agency,
        });
      }
    } catch (error) {
      console.log(response);
      console.error(error);
    }
    commit("SET_USER_COMPANY", response.company);
    const keysMap = {
      groupsList: "groups",
      managedProjectsList: "managedProjects",
      rolesList: "roles",
    };

    const userDataForRxdb = cloneDeep(response);
    delete userDataForRxdb.username;
    delete userDataForRxdb.dateJoined;
    delete userDataForRxdb.createdAt;
    delete userDataForRxdb.updatedAt;
    delete userDataForRxdb.lastSsoSync;
    delete userDataForRxdb.company;
    delete userDataForRxdb.agency;
    delete userDataForRxdb.rolesList;
    delete userDataForRxdb.$typeName;

    const { execOnCollection: userCollection } = useRxdbCollection("user");
    await userCollection((c) =>
      c.upsert({
        ...utils.grpc.renameKeys(keysMap, userDataForRxdb),
        isCurrentUser: true,
        agency: agency.uuid,
      })
    );
    return await User.insert({
      data: {
        ...utils.grpc.renameKeys(keysMap, response),
        isCurrentUser: true,
        agency: agency.uuid,
      },
    });
  },

  async partialUpdateUser(_, { uuid, language }) {
    const { execOnCollection: userCollection } = useRxdbCollection("user");
    try {
      const selector = { uuid };
      const [user] = await userCollection((c) => c.find({ selector }).exec());
      await user.incrementalUpdate({ $set: { language } });
      await amosUserClient.partialUpdate({
        uuid,
        language,
        PartialUpdateFields: ["language"],
      });
      const userInstance = User.query().where("uuid", uuid).first();
      userInstance.language = language;
      userInstance.$save();
    } catch (error) {
      console.warn(error);
    }
  },

  async updateUserSignature(_, { usermanagementUuid, signature }) {
    // INFO - NL - 13/02/2024 - Special call (not partialUpdate) as external users can update their signature
    // so this action is allowed in the back for them
    try {
      await User.update({
        where: (user) => user.usermanagementUuid === usermanagementUuid,
        data: { signature: signature },
      });
      await amosUserClient.updateUserSignature({
        usermanagementUuid,
        signature,
      });
    } catch (error) {
      console.warn(error);
    }
  },

  async searchUser(_, text) {
    const headers = { filters: JSON.stringify({ search: text }) };
    try {
      const { results } = await userManagementClient.list({}, { headers });
      const data = await User.insertOrUpdate({ data: results });
      return data.users;
    } catch (error) {
      console.warn(error);
    }
  },

  async getUsersFromUuids(context, uuids) {
    const uuidAsStr = uuids.filter((uuid) => !!uuid).join(",");
    const filters = { usermanagement_uuid__in: uuidAsStr };
    const headers = { filters: JSON.stringify(filters) };
    try {
      const { results } = await amosUserClient.list({}, { headers });
      await User.insertOrUpdate({ data: results });
      return results;
    } catch (error) {
      console.warn(error);
    }
  },

  async proVersionDemand(_, requestData) {
    try {
      await amosUserClient.proVersionDemand(requestData);
    } catch (error) {
      console.warn(error);
    }
  },
};

const mutations = {
  SET_USER_COMPANY: (state, userCompany) => {
    state.userCompany = userCompany;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
