import {
  // Disposition,
  DispositionNode,
} from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import referencial from "./referencial";
import { GRPC_REFERENCIAL_PARAMS } from "@/utils/const.js";
import { utils } from "@socotec.io/socio-vue-components";
import { maxBy } from "lodash";
import { filterDraftDispositionNodes } from "@/utils/referencialFilter";
import requestFactory from "@/store/factory";

const { sortNodesByPath } = utils.referencialUtils;

const {
  amos_back: { disposition: dispositionApi },
} = socioGrpcClient;

const state = {
  // referencial data state
  draftDispositionNodes: [],
  missionDispositionNodes: [],

  // root path data state
  dispositionReferencialRootPath: null,
  draftRootNodePath: null,

  // helpers and others
  treeDepthAsArray: [],
};

const getters = {
  getMissionDispositionNodes: (state) => state.missionDispositionNodes,
  getOrderedDraftNodes: (state) => state.draftDispositionNodes,
  getDraftRootNodePath: (state) => state.draftRootNodePath,
  getCurrentRootPath: (state) => state.dispositionReferencialRootPath,
};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! DispositionType pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  async fetchDispositionReferencial(
    { rootState, commit },
    {
      filters = {},
      extraParams = {},
      requestParams = GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_CURRENT_PARAMS,
      cacheValidationStrategy = () => false,
    }
  ) {
    // INFO - MS - async query to await DispotionType insertion finished
    await referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_CURRENT_PARAMS,
      ModelClass: DispositionNode,
      handleResponse: (nodes) => {
        commit("SET_DISPOSITION_ROOT", nodes[0].rootNodePath);
      },
      cacheValidationStrategy,
    })({ commit }, { filters, extraParams, requestParams });
    commit("SET_CURRENT_DISPOSITION_NODES", rootState);
  },

  // MS - INFO - 24/07/24 - Caution ! No logic for insert in rxdb
  async fetchDraftNodes({ commit, rootState }, { filters }) {
    const fetchDraftNodes = referencial.actions.referencialFactory({
      requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_DRAFT_PARAMS,
      ModelClass: DispositionNode,
      handleResponse: (nodes) => {
        commit("SET_DISPOSITION_DRAFT_ROOT_NODE_PATH", nodes[0].rootNodePath);
        nodes.sort(sortNodesByPath);
      },
      grpcAction: "flatListFullData",
    })({ commit }, { filters });
    await fetchDraftNodes;
    commit("SET_DRAFT_DISPOSITION_NODES", rootState);
  },

  // MS - INFO - 24/07/24 - Caution ! No logic for insert in rxdb
  simpleFetchDraftNodes: referencial.actions.referencialFactory({
    requestParams: GRPC_REFERENCIAL_PARAMS.AMOS_DISPOSITION_DRAFT_PARAMS,
    ModelClass: DispositionNode,
  }),

  async resetState({ commit }) {
    commit("RESET_MISSION_DISPOSITION_NODE");
    commit("SET_DISPOSITION_ROOT", null);
    DispositionNode.clearAggregateState();
    // A Disposition.deleteAll() was made here
    // it is commented because Disposition are needed in rxdb to fetch this data when offline;
    // delete simple wipe the rxdb cache and it can't be done.

    // we have to find a solution do make both worlds work
  },

  async destroyDisposition(_, disposition) {
    const request = new dispositionApi.DispositionDestroyRequest();
    request.setUuid(disposition.uuid);
    await dispositionApi.DispositionControllerPromiseClient.destroy(
      request,
      {}
    );
  },

  exportDispositions: requestFactory.actions.exportFactory(
    dispositionApi.DispositionControllerPromiseClient,
    dispositionApi.ExportRequest,
    "disposition"
  ),
};

const mutations = {
  SET_DISPOSITION_ROOT: (state, rootPath) => {
    state.dispositionReferencialRootPath = rootPath;
  },

  SET_MISSION_DISPOSITION_NODES: (state, missionDispositionNodes) => {
    state.missionDispositionNodes = missionDispositionNodes;
  },

  SET_TREE_DEPTH_AS_ARRAY: (state) => {
    if (!state.missionDispositionNodes?.length) {
      state.treeDepthAsArray = [];
      return;
    }
    const deepestNode = maxBy(state.missionDispositionNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DRAFT_DISPOSITION_NODES: (state, rootState) => {
    state.draftDispositionNodes = filterDraftDispositionNodes({ rootState });
    if (!state.draftDispositionNodes.length) {
      state.treeDepthAsArray = [];
      return;
    }

    const deepestNode = maxBy(state.draftDispositionNodes, "level");
    state.treeDepthAsArray = [...Array(deepestNode.level + 1).keys()].slice(1);
  },

  SET_DISPOSITION_DRAFT_ROOT_NODE_PATH: (state, rootPath) =>
    (state.draftRootNodePath = rootPath),

  RESET_MISSION_DISPOSITION_NODE: (state) => {
    state.missionDispositionNodes = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
