import { cloneDeep } from "lodash";

const state = {
  characteristic: {},
  characteristicInitCopy: {}, // for compare initial characteristic data and updated characteristic data and display a snackbar alert
};

const mutations = {
  SET_CHARACTERISTIC_DATA: (state, data) => {
    state.characteristic = data;
  },
  SET_CHARACTERISTIC_DATA_TO_SAVE: (state, data) => {
    state.characteristic = Object.assign({}, state.characteristic, data);
  },
  SET_CHARACTERISTIC_COPY: (state) => {
    state.characteristicInitCopy = cloneDeep(state.characteristic);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
