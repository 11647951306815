import { actions as _actions } from "./actions";
import { mutations as _mutations } from "./mutations";

const state = {
  observation: null,
  disposition: null,
  documentReview: null,
  ctNodeObservation: null,
  statements: {},
  actions: {},
  inheritedActions: {},
  defaultCreationState: "unknown",
};

const getters = {};

const actions = { ..._actions };

const mutations = { ..._mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
