/**
 * A.D.B. - 17-11-2022
 * Auth hooks module used to fix oidc-auth accesstoken renewal
 * handle unintended disconnections and auto restore sessions
 */

import { socioGrpcClient } from "@/setup/socioGrpcClient";
import router from "@/router";
import store from "@/store";

export const redirectLogin = async () => {
  console.log("redirect login page");
  store.commit("oidc/unsetOidcAuth");
  await store.dispatch("oidc/authenticateOidc", {
    redirectPath: router.currentRoute.fullPath,
  });
};

export const tryRefreshSessionOrRedirect = async () => {
  try {
    await store.dispatch("oidc/authenticateOidcSilent");
  } catch (err) {
    if (err.message.includes("Network Error")) {
      return;
    } else {
      console.log(
        "refresh session or redirect: authenticate silent error",
        err
      );
      await redirectLogin();
    }
  }
};

export const initializeAuthHooks = () => {
  // Try to refresh token if expired and refreshing stopped
  // if fail => redirect to login page
  //
  document.addEventListener("visibilitychange", () => {
    // we only care when the user switches to the page.
    if (document.hidden === false && store.state.appIsOnline) {
      if (store.getters["oidc/oidcAccessToken"]) {
        return;
      }
      console.log(
        "Automatic silent renew stopped due to inactivity, restarting..."
      );
      // async call that must be awaited if additional logic is added
      tryRefreshSessionOrRedirect();
    }
  });

  // before each request verify if token is still valid
  // try to renew it if not or redirect to login page
  socioGrpcClient.interceptors = {
    beforeRequest: async () => {
      // case when accessToken still valid in time
      if (store.getters["oidc/oidcAccessToken"]) {
        return;
      }
      console.log("Token expired trying to reconnect...");
      await tryRefreshSessionOrRedirect();
      return;
    },
    // On authentication error which means token is not expired but session expired
    // redirect to login page
    afterResponse: {
      success: async () => {},
      error: async ({ error }) => {
        // case when accessToken still valid in time but session expired
        // and revoked all tokens
        let message = null;
        try {
          message = JSON.parse(error.message);
        } catch {
          return;
        }
        if (message.code === "permission_denied") {
          // TODO: AS - 23/08/2023 instead of redirecting (infinite loop), show screen that tells user to check his access with admin
          // await redirectLogin();
          console.log(message.code);
        }
        return;
      },
    },
  };
};
