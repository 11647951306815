import { TASK_STATUS } from "@/utils/const";
import i18n from "@/setup/i18n";
import { Document, ProjectConfig, ProjectConfigAnalyticalAxis } from "@/models";
import { useRxdbCollection } from "@/composables/useRxdbCollection";
const state = {
  cacheProjectProcessing: false,
  replicationFailure: false,
  mustPullFromServer: false,
};

const getters = {
  getReplicationFailure: (state) => {
    return state.replicationFailure;
  },
  getIsMustPullFromServer: (state) => {
    return state.mustPullFromServer;
  },
};

const actions = {
  async cacheProject(
    { dispatch, commit, state },
    { projectUuid, userUuid, worker }
  ) {
    const { execOnCollection: cachedProjectCollection } =
      useRxdbCollection("cached_project");
    const document = await cachedProjectCollection(
      (c) =>
        c.upsert({
          project: projectUuid,
          user: userUuid,
          status: TASK_STATUS.IN_PROGRESS,
          createdAt: new Date().toISOString(),
          progress: 1,
        }),
      false
    );

    state.replicationFailure = false;
    try {
      commit("SET_REPLICATION_CYCLE_IN_PROCESS", true);
      commit("SET_MUST_PULL_FROM_SERVER", true);
      const { project } = await dispatch(
        "project/retrieveProjectData",
        { projectUuid },
        { root: true }
      );

      commit("project/SET_CURRENT_PROJECT", project.uuid, { root: true });
      worker.postMessage({
        messageName: "initObservableReplication",
        args: {
          visitUuid: projectUuid,
          mustPullFromServer: true,
        },
      });

      // must wait for a full replication cycle to be done before continueing
      let safeCount = 0;
      while (
        state.cacheProjectProcessing &&
        !state.replicationFailure &&
        safeCount < 10000
      ) {
        await new Promise((resolve) => setTimeout(resolve, 300));
        safeCount += 1;
      }
      if (state.replicationFailure || safeCount === 1000) {
        dispatch(
          "notifications/showErrorNotification",
          i18n.t("offline.cacheInError"),
          { root: true }
        );
        await document.incrementalUpdate({
          $set: { status: TASK_STATUS.FAILURE },
        });
      } else if (!state.replicationFailure) {
        await document.incrementalUpdate({
          $set: { status: TASK_STATUS.SUCCESS },
        });
        dispatch(
          "notifications/showSuccessNotification",
          i18n.t("offline.cacheInSuccess"),
          { root: true }
        );
      }
      worker.postMessage({
        messageName: "closeVisitReplication",
        args: projectUuid,
      });
      worker.postMessage({
        messageName: "closeObservableReplication",
        args: projectUuid,
      });
    } catch (error) {
      dispatch(
        "notifications/showErrorNotification",
        i18n.t("offline.cacheInError"),
        { root: true }
      );
      console.error(error);
      await document.incrementalUpdate({
        $set: { status: TASK_STATUS.ERROR },
      });
    } finally {
      await document.incrementalUpdate({
        $set: {
          progress: 100,
        },
      });
      commit("SET_MUST_PULL_FROM_SERVER", false);

      // NOTE G.P /!\ the "cache-in" of a visit should not populate the vuex store /!\.
      // However, some pull made by the "cache-in" process are dependent between each other.
      // this means a few models are always populated in the vuex to allow subsquequant pulls to be made.
      // we clean these from the vuex once the "cache-in" of a visit in done.
      dispatch("amosAos/resetState", null, { root: true });
      dispatch("asset/resetState", null, { root: true });
      ProjectConfig.deleteAll();
      ProjectConfigAnalyticalAxis.deleteAll();
      Document.deleteAll();
    }
  },
};

const mutations = {
  SET_REPLICATION_CYCLE_IN_PROCESS: (state, value) => {
    state.cacheProjectProcessing = value;
  },
  SET_FAILURE_REPLICATION: (state) => {
    state.replicationFailure = true;
  },
  SET_MUST_PULL_FROM_SERVER: (state, value) => {
    state.mustPullFromServer = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
