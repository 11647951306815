const state = {
  reSyncModalOpen: false,
  syncProcessModalOpen: false,
  reSyncModalLoading: false,
  syncStep: 1,
  reSyncErrorReasons: {
    aos_component: [],
    aos_observable: [],
    observation: [],
    b_i_m_data_pin: [],
    drawing: [],
  },
  collectionScheduledForResync: [],
  syncLoader: {
    aos_component: false,
    aos_observable: false,
    observation: false,
    b_i_m_data_pin: false,
    drawing: false,
  },
};

const getters = {
  isDisplayModalOpen: (state) => {
    return state.reSyncModalOpen;
  },
  isProcessModalOpen: (state) => {
    return state.syncProcessModalOpen;
  },
  getErrors: (state) => (collectionName) => {
    if (!Object.keys(state.reSyncErrorReasons).includes(collectionName)) {
      console.error("unknown key for getters in resync module");
      return [];
    }
    return state.reSyncErrorReasons[collectionName];
  },
  getCollectionScheduleNames: (state) => {
    return state.collectionScheduledForResync;
  },
  getSyncStep: (state) => {
    return state.syncStep;
  },
  getIsSyncStillLoading: (state) => {
    return (
      state.syncLoader.aos_component ||
      state.syncLoader.aos_observable ||
      state.syncLoader.observation ||
      state.syncLoader.b_i_m_data_pin ||
      state.syncLoader.drawing
    );
  },
  getIsInError: (state) => {
    return (
      state.reSyncErrorReasons.aos_component.length ||
      state.reSyncErrorReasons.aos_observable.length ||
      state.reSyncErrorReasons.observation.length ||
      state.reSyncErrorReasons.b_i_m_data_pin.length ||
      state.reSyncErrorReasons.drawing.length
    );
  },
  getCollectionLoadState: (state) => (collectionName) => {
    return state.syncLoader[collectionName];
  },
};

const actions = {
  setErrorReport({ commit }, { collectionName, elementsToPushInError }) {
    commit("SET_ERROR", {
      collectionName,
      data: elementsToPushInError,
    });
  },
  setSuccess({ commit }, collectionName) {
    commit("SET_ERROR", { collectionName, data: [] });
  },
  resetSyncErrorState({ commit }) {
    commit("RESET_ERROR_STATE");
  },
  setModalOpen({ commit }) {
    commit("SET_MODAL_STATUS", true);
  },
  closeResyncModal({ commit }) {
    commit("SET_MODAL_STATUS", false);
  },
  setSyncProgressOpen({ commit }) {
    commit("SET_PROGRESS_STATUS", true);
  },
  setSyncProgressClose({ commit }) {
    commit("SET_PROGRESS_STATUS", false);
  },
  addCollectionSchedule({ commit }, list) {
    commit("SET_COLLECTION_SCHEDULE", list);
  },
  resetCollectionSchedule({ commit }) {
    commit("SET_COLLECTION_SCHEDULE", []);
  },
  setCollectionLoading({ commit }, { collectionName, value }) {
    commit("SET_COLLECTION_LOADING", { collectionName, value });
  },
  resetSyncStep({ commit }) {
    commit("SET_SYNC_STEP", 1);
  },
  setNextSyncStep({ commit, getters }) {
    commit("SET_SYNC_STEP", getters["getSyncStep"] + 1);
  },
};

const mutations = {
  SET_ERROR: (state, { collectionName, data }) => {
    state.reSyncErrorReasons[collectionName] = data;
  },
  SET_DISPLAY_MODE: (state) => {
    state.reSyncModalOpen = state;
  },
  SET_PROGRESS_STATUS: (state, value) => {
    state.syncProcessModalOpen = value;
  },
  RESET_ERROR_STATE: (state) => {
    state.reSyncErrorReasons = {
      aosComponent: [],
      aosObservable: [],
      observation: [],
      bimDataPin: [],
      drawing: [],
    };
  },
  SET_MODAL_STATUS: (state, value) => {
    state.reSyncModalOpen = value;
  },
  SET_COLLECTION_SCHEDULE: (state, value) => {
    state.collectionScheduledForResync = value;
  },
  SET_COLLECTION_LOADING: (state, { collectionName, value }) => {
    state.syncLoader[collectionName] = value;
  },
  SET_SYNC_STEP: (state, step) => {
    state.syncStep = step;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
