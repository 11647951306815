import {
  camelToSnakeCase,
  formatFilters,
  getFieldValueFromFieldsMap,
} from "@/utils/helpers";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { setRequestFilters } from "@/utils/request";
import { TASK_STATUS } from "@/utils/const";
import dayjs from "dayjs";
import { downloadDocument } from "@/utils/document";

import {
  utils,
  useAosEventBus,
  aosComposables,
} from "@socotec.io/socio-aos-component";

const { move, remove } = aosComposables.useStructure();
const { aosEventBus } = useAosEventBus();

export const baseExcludedFields = [
  "createdAt",
  "createdBy",
  "updatedAt",
  "modifiedBy",
  "$id",
  "pivot",
];

export const getters = {
  paginateGetter: (query, sortFunc) => {
    return (pageSize, pageNumber) => {
      let items = [];

      if (!pageSize || !pageNumber || pageNumber < 1 || pageSize < 1) {
        items = query.get();
      } else {
        items = query
          .limit(pageSize)
          .offset((pageNumber - 1) * pageSize)
          .where("displayed", true)
          .get();
      }

      if (!sortFunc) {
        return items;
      }

      return items.sort(sortFunc);
    };
  },
};

export const genericActions = {
  streamedTaskFactory:
    ({
      client,
      grpcRequest,
      requestMethod,
      modelName,
      requestParameters = {},
      onSuccess = { message: "taskSuccess", action: undefined },
      onFailure = { message: "taskFailure", action: undefined },
      onPending = { message: "taskPending", action: undefined },
      onProgress = { message: "taskInfo", action: undefined },
    }) =>
    async ({ dispatch }, { filters = {}, metadata = {} } = {}) => {
      const request = setRequestFilters({
        request: socioGrpcClient.javascriptToRequest(
          grpcRequest,
          requestParameters
        ),
        filters,
      });

      try {
        const stream = await client[requestMethod](request, metadata);

        stream.on("data", async (response) => {
          const responseData = response.toObject();
          let data = {
            ...responseData,
            model: modelName,
          };
          switch (responseData.status) {
            case TASK_STATUS.SUCCESS:
              data = {
                ...data,
                message: onSuccess.message,
                action: onSuccess.action && {
                  label: onSuccess.action.label,
                  handler: () => onSuccess.action.handler(responseData),
                },
              };
              break;
            case TASK_STATUS.FAILURE:
              data = {
                ...data,
                message: onFailure.message,
                action: onFailure.action && {
                  label: onFailure.action.label,
                  handler: () => onFailure.action.handler(responseData),
                },
              };
              break;
            case TASK_STATUS.IN_PROGRESS:
              data = {
                ...data,
                message: onProgress.message,
                action: onProgress.action && {
                  label: onProgress.action.label,
                  handler: () => onProgress.action.handler(responseData),
                },
              };
              break;
            default:
              data = {
                ...data,
                message: onPending.message,
                action: onPending.action && {
                  label: onPending.action.label,
                  handler: () => onPending.action.handler(responseData),
                },
              };
          }
          dispatch("task/updateTasks", [data], { root: true });
        });
      } catch (err) {
        dispatch("notifications/showErrorNotification", "export.error", {
          root: true,
        });
        console.error(err);
      }
    },
};

export const actions = {
  listFactory:
    ({
      ModelClass,
      client,
      grpcListRequest,
      transformResponseData = (x) => x,
    }) =>
    async (_, { filters = {}, pagination = {}, requestData = {} }) => {
      const metadata = {
        filters: formatFilters(filters),
        pagination: JSON.stringify(pagination),
      };
      const request = new grpcListRequest();

      // allows to pass uuids in grpcListRequest
      if (!requestData.uuids?.length) {
        return [];
      } else if (request.uuids?.length > 0) {
        request.setUuidsList(requestData.uuids ?? []);
      }

      const response = await client.list(request, metadata);
      const data = transformResponseData(response.toObject().resultsList);
      await ModelClass.insert({ data });
    },

  tableListerFactory:
    ({
      ModelClass,
      grpcListRequest,
      grpcListMethod,
      onItemsInserted = (_, itemsCount, results) => [itemsCount, results],
      transformResponseData = async (res) => res,
    }) =>
    async ({ commit }, { filters = {}, page, pageSize }) => {
      const metadata = {
        pagination: JSON.stringify({
          page: page,
          page_size: pageSize,
        }),
      };
      const request = setRequestFilters({
        request: new grpcListRequest(),
        filters,
      });
      const response = await grpcListMethod(request, metadata);
      const { resultsList, count } = response.toObject();
      await transformResponseData(resultsList);
      await ModelClass.update({
        data: { displayed: false },
      });
      await ModelClass.insert({
        data: resultsList.map((r) => ({ ...r, displayed: true })),
      });
      onItemsInserted(commit, count, resultsList);
      return ModelClass.findIn(resultsList.map((r) => r.uuid));
    },

  retrieveFactory:
    (
      ModelClass,
      grpcRetrieveRequest,
      client,
      transformResponseData = (x) => x
    ) =>
    async (_, itemUuid) => {
      const request = new grpcRetrieveRequest();

      request.setUuid(itemUuid);

      const response = await client.retrieve(request, {});
      const item = transformResponseData(response.toObject());

      await ModelClass.insert({ data: item });

      return ModelClass.find(itemUuid);
    },

  partialUpdateFactory:
    (
      ModelClass,
      grpcPartialUpdateRequest,
      client,
      extraExcludedFields = [],
      transformRequestData = (x) => x,
      transformResponseData = (x) => x
    ) =>
    async (_, data) => {
      const excludedFields = [
        ...baseExcludedFields,
        ...extraExcludedFields,
        ...Object.keys(ModelClass.fields()).filter((f) => f.endsWith("Data")),
      ];
      data = transformRequestData({ ...data });

      const request = socioGrpcClient.javascriptToRequest(
        grpcPartialUpdateRequest,
        data,
        excludedFields
      );

      request.setPartialUpdateFieldsList(
        Object.keys(request.toObject()).map((key) => {
          const toSnake = camelToSnakeCase(key);
          return toSnake.endsWith("_list")
            ? toSnake.replace("_list", "")
            : toSnake;
        })
      );

      const response = await client.partialUpdate(request, {});
      await ModelClass.insertOrUpdate({
        data: transformResponseData(response.toObject()),
      });
      return response.toObject();
    },

  specificPartialUpdateFactory:
    (
      ModelClass,
      grpcPartialUpdateRequest,
      client,
      extraExcludedFields = [],
      transformRequestData = (x) => x,
      transformResponseData = (x) => x
    ) =>
    async (_, data) => {
      // Update only the keys inside the data object
      const excludedFields = [
        ...baseExcludedFields,
        ...extraExcludedFields,
        ...Object.keys(ModelClass.fields()).filter((f) => f.endsWith("Data")),
      ];
      const dataKeys = Object.keys(data).map((key) => {
        const toSnake = camelToSnakeCase(key);
        return toSnake.endsWith("_list")
          ? toSnake.replace("_list", "")
          : toSnake;
      });
      data = transformRequestData({ ...data });

      const request = socioGrpcClient.javascriptToRequest(
        grpcPartialUpdateRequest,
        data,
        excludedFields
      );
      request.setPartialUpdateFieldsList(dataKeys);
      const response = await client.partialUpdate(request, {});
      await ModelClass.insertOrUpdate({
        data: {
          ...transformResponseData(response.toObject()),
          createdOrUpdatedOffline: false,
          synchronisationState: "synced",
        },
      });
      return response.toObject();
    },

  exportFactory:
    (client, grpcExportRequest, modelName, exportMethod = "export") =>
    async (
      { dispatch, rootGetters },
      { filters = {}, extraRequestParams = {}, metadata = {} }
    ) => {
      const project = rootGetters["project/getCurrentProject"];
      const fileName = `${
        project.caseNumber
      }_${modelName}s_export_${dayjs().format(
        "DD_MM_YYYY_HH_mm_ss"
      )}.xlsx`.replaceAll(" ", "_");
      const requestParameters = {
        fileName,
        targetEntityId: `${project.uuid}`,
        originId: `${project.originId}`,
        targetEntityName: "project",
        ...extraRequestParams,
      };
      await genericActions.streamedTaskFactory({
        client,
        grpcRequest: grpcExportRequest,
        modelName,
        requestParameters,
        requestMethod: exportMethod,
        onSuccess: {
          message: "export.success",
          action: {
            label: "download",
            handler: (responseData) => {
              const documentCustomUuid = getFieldValueFromFieldsMap(
                responseData.result.fieldsMap,
                "document_custom_uuid",
                "stringValue"
              );
              downloadDocument(documentCustomUuid);
            },
          },
        },
        onFailure: {
          message: "export.error",
        },
        onPending: {
          message: "export.pending",
        },
        onProgress: {
          message: "export.inProgress",
        },
      })({ dispatch }, { filters, metadata });
    },

  duplicateFactory: (client, grpcRequest, modelName, method = "duplicate") =>
    async function ({ dispatch }, requestParameters) {
      const request = socioGrpcClient.javascriptToRequest(
        grpcRequest,
        requestParameters
      );

      try {
        const stream = await client[method](request, {});

        stream.on("data", async (response) => {
          const responseData = response.toObject();

          let data = {
            ...responseData,
            model: modelName,
          };

          switch (responseData.status) {
            case TASK_STATUS.SUCCESS:
              data = {
                ...data,
                message: "duplication.success",
                action: {
                  label: "load",
                  handler: async () => {
                    await dispatch(
                      "asset/fetchAssets",
                      {
                        projectUuid: requestParameters.projectId,
                      },
                      { root: true }
                    );
                  },
                },
              };
              break;
            case TASK_STATUS.FAILURE:
              data.message = "duplication.failure";
              break;
            case TASK_STATUS.IN_PROGRESS:
              data.message = "duplication.inProgress";
              break;
            default:
              data.message = "duplication.pending";
          }
          dispatch("task/updateTasks", [data], { root: true });
        });
      } catch (err) {
        dispatch("notifications/showErrorNotification", "duplication.error", {
          root: true,
        });
        console.error(err);
      }
    },

  moveFactory: (client, grpcRequest, modelName, method = "move") =>
    async function ({ dispatch, rootGetters }, requestParameters) {
      const request = socioGrpcClient.javascriptToRequest(
        grpcRequest,
        requestParameters
      );

      try {
        const stream = await client[method](request, {});

        stream.on("data", async (response) => {
          const responseData = {
            ...response.toObject(),
            result: response.getResult()?.toJavaScript(),
            metadata: response.getMetadata()?.toJavaScript(),
          };

          let data = {
            ...responseData,
            model: modelName,
          };

          switch (responseData.status) {
            case TASK_STATUS.SUCCESS: {
              await move(responseData);

              let perimeterAssets =
                rootGetters["asset/getAosItemsUuidsFromAssets"];

              perimeterAssets.component.push(
                ...responseData.result.created.map(({ uuid }) => uuid)
              );

              aosEventBus.$emit(
                utils.aosEventsConst.AOS_EVENTS_LISTENED[
                  "UPDATE_REQUEST_FILTERS"
                ].eventName,
                { uuids: perimeterAssets }
              );

              data = {
                ...data,
                message: "move.success",
              };
              break;
            }
            case TASK_STATUS.FAILURE:
              data.message = "move.failure";
              break;
            case TASK_STATUS.IN_PROGRESS:
              data.message = "move.inProgress";
              break;
            default:
              data.message = "move.pending";
          }
          dispatch("task/updateTasks", [data], { root: true });
        });
      } catch (err) {
        dispatch("notifications/showErrorNotification", "move.error", {
          root: true,
        });
        console.error(err);
      }
    },

  deleteFactory: (client, grpcRequest, modelName, method = "delete") =>
    async function ({ dispatch }, requestParameters) {
      console.log("deleteFactory", requestParameters);
      const request = socioGrpcClient.javascriptToRequest(
        grpcRequest,
        requestParameters
      );

      try {
        const stream = await client[method](request, {});

        stream.on("data", async (deleteResponse) => {
          const responseData = {
            ...deleteResponse.toObject(),
            result: deleteResponse.getResult()?.toJavaScript(),
            metadata: deleteResponse.getMetadata()?.toJavaScript(),
          };

          let data = {
            ...responseData,
            model: modelName,
          };

          switch (responseData.status) {
            case TASK_STATUS.SUCCESS: {
              await remove(responseData);
              data = {
                ...data,
                message: "remove.success",
              };
              break;
            }
            case TASK_STATUS.FAILURE:
              data.message = "remove.failure";
              break;
            case TASK_STATUS.IN_PROGRESS:
              data.message = "remove.inProgress";
              break;
            default:
              data.message = "remove.pending";
          }
          dispatch("task/updateTasks", [data], { root: true });
        });
      } catch (err) {
        dispatch("notifications/showErrorNotification", "delete.error", {
          root: true,
        });
        console.error(err);
      }
    },
};

export default { genericActions, actions, baseExcludedFields, getters };
