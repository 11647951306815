import { Task } from "@/models";
import socioGrpcClient from "@/setup/socioGrpcClient";
import { TASK_STATUS } from "@/utils/const";
import store from "@/store";
import { setRequestFilters } from "@/utils/request";

const {
  amos_back: { task: TaskApi },
} = socioGrpcClient;

const client = TaskApi.TaskControllerPromiseClient;

const state = {};

const getters = {
  getAllTasks: () => Task.query().orderBy("createdAt", "desc").get(),
  getRunningModelTasks: () => (modelName) => {
    return Task.query()
      .where(
        ({ status, model }) =>
          [TASK_STATUS.IN_PROGRESS, TASK_STATUS.PENDING].includes(status) &&
          model.toLowerCase() === modelName.toLowerCase()
      )
      .exists();
  },
};

const actions = {
  async removeTask(_, taskUuid) {
    await Task.delete(taskUuid);
  },
  async removeAllTasks() {
    await Task.deleteAll();
  },
  async addTask(_, payload) {
    await Task.insert({ data: payload });
  },
  async updateTask(_, payload) {
    await Task.update({ where: payload.uuid, data: payload });
  },
  async updateTasks(_, payload) {
    await Task.insertOrUpdate({ data: payload });
  },
  async fetchRunningTasks() {
    const request = setRequestFilters({
      request: new TaskApi.TaskResponseListRequest(),
      filters: {
        created_by: store.getters["user/getCurrentUser"].usermanagementUuid,
        status__in: [TASK_STATUS.IN_PROGRESS, TASK_STATUS.PENDING].join(","),
      },
    });
    const response = await client.list(request, {});
    const data = response.toObject().resultsList;
    await Task.insertOrUpdate({ data });
    return data;
  },

  async retrieveTaskProgress({ dispatch }, taskUuid) {
    const request = new TaskApi.TaskResponseRetrieveRequest();
    request.setUuid(taskUuid);
    try {
      const stream = await client.retrieveTaskProgress(request);
      stream.on("data", async (response) => {
        const responseData = response.toObject();
        dispatch("task/updateTasks", [responseData], { root: true });
      });
    } catch (error) {
      dispatch(
        "notifications/showErrorNotification",
        "taskProgressFetch.error",
        {
          root: true,
        }
      );
      console.error(error);
    }
  },
};

export default {
  namespaced: true,
  actions,
  state,
  getters,
};
