import Vue from "vue";
import i18n from "@/setup/i18n";

import Vuetify, {
  VCardSubtitle,
  VCard,
  VChip,
  VCardText,
  VCardTitle,
  VCardActions,
  VContainer,
  VDialog,
  VDivider,
  VAutocomplete,
  VLayout,
  VProgressCircular,
  VList,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemIcon,
  VApp,
  VAppBar,
  VSlideItem,
  VSpacer,
  VMenu,
  VSlideGroup,
  VBtn,
  VIcon,
  VToolbarTitle,
  VSheet,
  VRow,
  VCol,
  VTooltip,
  VAvatar,
  VDataTable,
  VToolbar,
  VSnackbar,
  VListGroup,
  VTreeview,
  VStepper,
  VStepperHeader,
  VStepperStep,
  VStepperItems,
  VStepperContent,
  VForm,
  VPagination,
  VTextField,
  VExpandTransition,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VCheckbox,
  VSelect,
  VCombobox,
  VBadge,
  VDatePicker,
  VSwitch,
  VListItemSubtitle,
  VImg,
  VProgressLinear,
  VRadioGroup,
  VRadio,
  VSlider,
  VTabs,
  VTab,
  VTabsItems,
  VTabItem,
  VHover,
  VSimpleCheckbox,
  VOverlay,
  VNavigationDrawer,
  VBtnToggle,
  VListItemAction,
  VSpeedDial,
  VSubheader,
  VTabsSlider,
  VTextarea,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VSkeletonLoader,
  VDataIterator,
} from "vuetify/lib";

import {
  Ripple,
  Intersect,
  Touch,
  Resize,
  ClickOutside,
} from "vuetify/lib/directives";

Vue.use(Vuetify, {
  components: {
    VApp,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VContainer,
    VDialog,
    VChip,
    VDivider,
    VAutocomplete,
    VLayout,
    VProgressCircular,
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VAppBar,
    VSlideItem,
    VSpacer,
    VMenu,
    VSlideGroup,
    VBtn,
    VIcon,
    VToolbarTitle,
    VListItemIcon,
    VSheet,
    VRow,
    VCol,
    VTooltip,
    VAvatar,
    VDataTable,
    VToolbar,
    VSnackbar,
    VListGroup,
    VTreeview,
    VStepper,
    VStepperHeader,
    VStepperStep,
    VStepperItems,
    VStepperContent,
    VForm,
    VPagination,
    VTextField,
    VExpandTransition,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VCheckbox,
    VSelect,
    VCombobox,
    VBadge,
    VDatePicker,
    VSwitch,
    VListItemSubtitle,
    VImg,
    VProgressLinear,
    VRadioGroup,
    VRadio,
    VSlider,
    VTabs,
    VTab,
    VTabsItems,
    VTabItem,
    VHover,
    VCardSubtitle,
    VSimpleCheckbox,
    VOverlay,
    VNavigationDrawer,
    VBtnToggle,
    VListItemAction,
    VSpeedDial,
    VSubheader,
    VTabsSlider,
    VTextarea,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VSkeletonLoader,
    VDataIterator,
  },
  directives: {
    Ripple,
    Intersect,
    Touch,
    Resize,
    ClickOutside,
  },
  iconfont: "md",
});

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
});
