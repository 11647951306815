import { socioGrpcClient } from "@/setup/socioGrpcClient";
// import { Client } from "@socotec.io/socio-vue-components";

const state = {
  currentClient: null,
  clientsList: null,
};

const getters = {
  getCurrentClient: (state) => {
    return state.currentClient;
  },
  getClientUuid: (state) => {
    return state.currentClient ? state.currentClient.uuid : null;
  },
};

const mutations = {
  SET_CURRENT_CLIENT: (state, client) => {
    state.currentClient = client;
  },
};

const actions = {
  /**
   * Retrieve a client
   * @param [_]
   * @param clientId Client uuid
   * @returns {Promise<Client>}
   */
  async retrieveClient(_, clientId) {
    const request =
      new socioGrpcClient.amos_back.projects.ClientRetrieveRequest();

    request.setUuid(clientId);

    const response =
      await socioGrpcClient.amos_back.projects.ClientControllerPromiseClient.retrieve(
        request,
        {}
      );
    const client = response.toObject();

    return client;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
