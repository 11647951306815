import Vue from "vue";
import VueI18n from "vue-i18n";
import { utils } from "@socotec.io/socio-vue-components";
import { messages } from "@/lang/messages/index";
import { dateTimeFormats } from "@/lang/dateTimeFormats/index";
import { aosMessages } from "@socotec.io/socio-aos-component";

Vue.use(VueI18n);

const allMessages = {
  "en-gb": {
    $vuetify: utils.globalVuetify.enGlobalVuetify,
    ...messages.en,
    ...aosMessages.en,
  },
  fr: {
    $vuetify: utils.globalVuetify.frGlobalVuetify,
    ...messages.fr,
    ...aosMessages.fr,
  },
};

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: allMessages,
  dateTimeFormats,
  silentFallbackWarn: true,
});
