import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { Scenario, ExpenseOverTime, YearlyBudget } from "@/models";
import requestFactory from "../factory";

const {
  amos_back: { arbitration: arbitrationApi },
} = socioGrpcClient;
const baseExcludedFields = ["createdAt", "updatedAt", "$id"];
const expenseOverTimeExcludedFields =
  baseExcludedFields + ["isSelected", "isSelectable"];

const state = {
  scenariosCount: 0,
};

const getters = {
  getAllScenarios: () => {
    return Scenario.all();
  },
  getMainScenario: () => (project) => {
    return Scenario.query()
      .where("isMainScenario", true)
      .where("project", project)
      .first();
  },
  getScenarios: () => requestFactory.getters.paginateGetter(Scenario.query()),
  getScenariosCount: (state) => state.scenariosCount,
  isFirstScenarioSentInReport: () => (projectUuid) => {
    const scenario = Scenario.query()
      .where("project", projectUuid)
      .where("version", 1)
      .first();
    if (!scenario) return false;
    return scenario.isInSentReport;
  },
};

const actions = {
  async resetState() {
    await Scenario.deleteAll();
  },

  fetchScenarios: requestFactory.actions.tableListerFactory({
    ModelClass: Scenario,
    grpcListRequest: arbitrationApi.ScenarioListRequest,
    grpcListMethod: arbitrationApi.ScenarioControllerPromiseClient.list,
    onItemsInserted: (commit, count) => commit("SET_SCENARIOS_COUNT", count),
  }),

  async retrieveScenario(_, uuid) {
    const request = new arbitrationApi.ScenarioRetrieveRequest();
    request.setUuid(uuid);
    const response =
      await arbitrationApi.ScenarioControllerPromiseClient.retrieve(
        request,
        {}
      );
    return await Scenario.insertOrUpdate({ data: response.toObject() });
  },

  async createScenario({ commit, getters }, { data }) {
    const excludedFields = ["actions", "lastUpdate", ...baseExcludedFields];
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.ScenarioRequest,
      { ...data },
      excludedFields
    );

    const result = await arbitrationApi.ScenarioControllerPromiseClient.create(
      request,
      {}
    );
    commit("SET_SCENARIOS_COUNT", getters.getScenariosCount + 1);
    await Scenario.insert({ data: result.toObject() });
  },

  async updateScenario(_, { data }) {
    const excludedFields = ["actions", "lastUpdate", ...baseExcludedFields];
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.ScenarioRequest,
      { ...data },
      excludedFields
    );
    const result = await arbitrationApi.ScenarioControllerPromiseClient.update(
      request,
      {}
    );
    return await Scenario.update({
      where: data.uuid,
      data: { ...result.toObject(), isInSentReport: data.isInSentReport },
    });
  },

  async duplicateScenario({ commit, getters }, { data }) {
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.ScenarioRequest,
      { ...data }
    );
    const result =
      await arbitrationApi.ScenarioControllerPromiseClient.duplicate(
        request,
        {}
      );
    commit("SET_SCENARIOS_COUNT", getters.getScenariosCount + 1);
    Scenario.insert({ data: result.toObject() });
  },

  async deleteScenario({ commit, getters }, { uuid }) {
    const request = new arbitrationApi.ScenarioDestroyRequest();
    request.setUuid(uuid);
    await arbitrationApi.ScenarioControllerPromiseClient.destroy(request, {});
    commit("SET_SCENARIOS_COUNT", getters.getScenariosCount - 1);
    Scenario.delete(uuid);
  },

  // Expenses over time

  async fetchExpensesOverTime(_, { uuid }) {
    const request = new arbitrationApi.ExpenseOverTimeListForScenarioRequest();
    request.setUuid(uuid);
    const response =
      await arbitrationApi.ExpenseOverTimeControllerPromiseClient.listForScenario(
        request,
        {}
      );
    const { resultsList } = response.toObject();
    // Create to clean to destroy existing values
    await ExpenseOverTime.create({ data: resultsList });
  },

  async listExpensesOverTimeForAction(_, { action, scenario }) {
    const request = new arbitrationApi.ExpenseOverTimeListForActionRequest();
    request.setScenario(scenario);
    request.setAction(action);

    const response =
      await arbitrationApi.ExpenseOverTimeControllerPromiseClient.listForAction(
        request,
        {}
      );
    const { resultsList } = response.toObject();
    await ExpenseOverTime.insertOrUpdate({ data: resultsList });
  },

  async updateExpensesOverTime(_, { expensesOverTime }) {
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.ExpensesOverTimeListRequest,
      {}
    );
    request.setExpensesOverTimeList(
      expensesOverTime.map((expense) => {
        return socioGrpcClient.javascriptToRequest(
          arbitrationApi.ExpenseOverTimeRequest,
          expense,
          expenseOverTimeExcludedFields
        );
      })
    );
    const response =
      await arbitrationApi.ExpenseOverTimeControllerPromiseClient.updateOrCreateMany(
        request,
        {}
      );
    const { resultsList } = response.toObject();
    // Delete expensesOverTime and insert new ones because the expensesOverTime in vuex-orm DB could not exist in back DB (haven't been created)
    // and so a new uuid will be sent by the back => not possible to update
    ExpenseOverTime.deleteAndUpdate(expensesOverTime, resultsList);
  },
  async splitExpensesOverTime(_, { expenseOverTime, numberSplits }) {
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.ExpensesOverTimeSplitRequest,
      expenseOverTime,
      expenseOverTimeExcludedFields
    );
    request.setNumberSplits(numberSplits);
    const response =
      await arbitrationApi.ExpenseOverTimeControllerPromiseClient.split(
        request,
        {}
      );
    const { resultsList } = response.toObject();
    if (resultsList.length) {
      // Delete merged expenses and insert new one
      ExpenseOverTime.deleteAndUpdate(expenseOverTime, resultsList);
    }
  },
  async mergeExpensesOverTime(_, { expensesOverTime }) {
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.ExpensesOverTimeListRequest,
      {}
    );
    request.setExpensesOverTimeList(
      expensesOverTime.map((expense) => {
        return socioGrpcClient.javascriptToRequest(
          arbitrationApi.ExpenseOverTimeRequest,
          expense,
          expenseOverTimeExcludedFields
        );
      })
    );
    const response =
      await arbitrationApi.ExpenseOverTimeControllerPromiseClient.merge(
        request,
        {}
      );
    if (response) {
      // Delete merged expenses and insert new one
      ExpenseOverTime.deleteAndUpdate(expensesOverTime, response.toObject());
    }
  },

  // Yearly Budget

  async fetchYearlyBudgets(_, { filters }) {
    const metadata = {
      filters: JSON.stringify(filters),
    };
    const request = new arbitrationApi.YearlyBudgetListRequest();
    const response =
      await arbitrationApi.YearlyBudgetControllerPromiseClient.list(
        request,
        metadata
      );
    const { resultsList } = response.toObject();
    // Create to destroy existing yearly budgets
    YearlyBudget.create({ data: resultsList });
  },

  async updateOrCreateYearlyBudget(_, { data }) {
    let request = socioGrpcClient.javascriptToRequest(
      arbitrationApi.YearlyBudgetRequest,
      { ...data }
    );
    const result =
      await arbitrationApi.YearlyBudgetControllerPromiseClient.updateOrCreate(
        request,
        {}
      );
    YearlyBudget.deleteAndUpdate(data, result.toObject());
  },

  async deleteYearlyBudgetScenario(_, { uuid }) {
    const request =
      new arbitrationApi.YearlyBudgetDeleteScenarioYearlyBudgetsRequest();
    request.setUuid(uuid);
    await arbitrationApi.YearlyBudgetControllerPromiseClient.deleteScenarioYearlyBudgets(
      request,
      {}
    );
  },
};

const mutations = {
  SET_SCENARIOS_COUNT: (state, count) => (state.scenariosCount = count),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
