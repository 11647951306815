import { socioGrpcClient } from "@/setup/socioGrpcClient";
import BuildingAsset from "@/models/BuildingAsset";
import { setNestedRequest } from "@/utils/request";
import requestFactory from "../factory";
import { cloneDeep } from "lodash";
import BuildingCharacteristic from "@/models/BuildingCharacteristic";

const client = socioGrpcClient.amos_back.aos.BuildingControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;

export function InfrastructureCharacteristics(data = {}) {
  return {
    uuid: data.uuid,
    nbrOfDecks: data.nbrOfDecks,
    nbrOfBays: data.nbrOfBays,
    range: data.range,
    airDraft: data.airDraft,
    waterDraft: data.waterDraft,
    bias: data.bias,
    totalLength: data.totalLength,
    usefulLength: data.usefulLength,
    totalWidth: data.totalWidth,
    usefulWidth: data.usefulWidth,
    roadwayWidth: data.roadwayWidth,
    sidewalkWidth: data.sidewalkWidth,
    railingHeight: data.railingHeight,
  };
}

const state = {
  infrastructureData: InfrastructureCharacteristics(),
  infrastructureDataInitCopy: InfrastructureCharacteristics(),
};

const getters = {
  getInfrastructureData: () => {
    return state.infrastructureData;
  },
};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! BuildingAsset pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  fetchBuildings: async (_, { metadata = {}, listAll = false }) => {
    const request = new socioGrpcClient.amos_back.aos.BuildingListRequest();

    const response = listAll
      ? await client.utils.listAllObjects(request, metadata)
      : await client.list(request, metadata);

    const data = listAll ? response : response.toObject().resultsList;

    const transformedData = data.map((s) => ({ ...s, tagsData: s.tagsList }));
    await BuildingAsset.insertOrUpdate({
      data: transformedData,
    });
    return transformedData;
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.BuildingRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    const characteristics = response.toObject();
    await BuildingCharacteristic.insertOrUpdate({ data: characteristics });
    return characteristics;
  },
  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    BuildingAsset,
    socioGrpcClient.amos_back.aos.BuildingCharacteristicsPartialUpdateRequest,
    client,
    ["code"],
    (data) => {
      const excludedFields = ["code"];
      setNestedRequest(
        data,
        "constructionList",
        socioGrpcClient.amos_back.aos.ConstructionRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "surfaceList",
        socioGrpcClient.amos_back.aos.SurfaceRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "labelCertificationList",
        socioGrpcClient.amos_back.aos.LabelCertificationRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "theoricalWorkforceList",
        socioGrpcClient.amos_back.aos.TheoricalWorkforceRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "clearanceList",
        socioGrpcClient.amos_back.aos.ClearanceRequest,
        excludedFields
      );
      setNestedRequest(
        data,
        "visitHistoryList",
        socioGrpcClient.amos_back.aos.VisitHistoryRequest,
        excludedFields
      );
      return data;
    }
  ),
  updateCode: async (_, { uuid, code }) => {
    const request =
      new socioGrpcClient.amos_back.aos.BuildingUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    BuildingAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "BuildingAsset"
  ),
  move: requestFactory.actions.moveFactory(
    client,
    socioGrpcClient.amos_back.aos.MoveRequest,
    "BuildingAsset"
  ),
  delete: requestFactory.actions.deleteFactory(
    client,
    socioGrpcClient.amos_back.aos.DeleteRequest,
    "BuildingAsset"
  ),
};

const mutations = {
  SET_INFRASTRUCTURE_DATA: (state, data) => {
    state.infrastructureData = data;
  },
  SET_INFRASTRUCTURE_DATA_COPY: (state) => {
    state.infrastructureDataInitCopy = cloneDeep(state.infrastructureData);
  },
  SET_INFRASTRUCTURE_DATA_TO_SAVE: (state, data) => {
    state.infrastructureData = Object.assign(
      InfrastructureCharacteristics(),
      state.infrastructureData,
      InfrastructureCharacteristics(data)
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
