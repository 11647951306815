import SpaceAsset from "@/models/SpaceAsset";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import requestFactory from "../factory";

const client = socioGrpcClient.amos_back.aos.SpaceControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;

const buildAction = (actionType, requestType) => {
  return requestFactory.actions[actionType](
    client,
    socioGrpcClient.amos_back.aos[requestType],
    "SpaceAsset"
  );
};

const getters = {};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! SpaceAsset pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  fetchSpaces: async (_, { metadata = {}, listAll = false }) => {
    const request = new socioGrpcClient.amos_back.aos.SpaceListRequest();
    const response = listAll
      ? await client.utils.listAllObjects(request, metadata)
      : await client.list(request, metadata);

    const data = listAll ? response : response.toObject().resultsList;
    await SpaceAsset.insert({
      data,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.SpaceRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    SpaceAsset,
    socioGrpcClient.amos_back.aos.SpaceCharacteristicsPartialUpdateRequest,
    client,
    ["code"]
  ),
  updateCode: async (_, { uuid, code }) => {
    const request = new socioGrpcClient.amos_back.aos.SpaceUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    SpaceAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: buildAction("duplicateFactory", "DuplicationRequest"),
  move: buildAction("moveFactory", "MoveRequest"),
  delete: buildAction("deleteFactory", "DeleteRequest"),
};

const mutations = {};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
};
