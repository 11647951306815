import ProjectConfig from "@/models/ProjectConfig";

import BuildingCharacteristic from "@/models/BuildingCharacteristic";
import Project from "@/models/Project";
import { AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import { RapsotecNodeAmos } from "@/models/RapsotecNode";
import {
  CT_DESTINATION_CODES_MAPPING,
  CT_TYPE_CODES_MAPPING,
} from "@/setup/referencialRulesMapping";
import { FilterRule } from "@/utils/referencialFilterRules";

const codesMappingRule = (rules, buildingCharacteristics) => {
  return buildingCharacteristics.some((characteristics) => {
    return rules.some((rule) => {
      return Object.entries(rule).every(([prop, expectedValue]) => {
        const value = characteristics[prop];
        return (
          Object.hasOwnProperty.call(characteristics, prop) &&
          (value === expectedValue ||
            (Array.isArray(value) && value.includes(expectedValue)))
        );
      });
    });
  });
};

export const ReferencialMappingConfig = {
  // Conditional display rules mapping
  conditionalDisplay: {
    "case|case_postcode": new FilterRule({
      targetEntity: AosVuexOrmModel.AosLocation,
      targetAttrs: ["postalCode"],
      getCollection: (data) =>
        data.buildings.flatMap((b) => b.locations.map((l) => l.postalCode)),
    }),
    "project|building_permit_request_date": new FilterRule({
      targetEntity: BuildingCharacteristic,
      targetAttrs: ["constructionList"],
      getCollection: (data) =>
        data.buildingAssets.flatMap((asset) =>
          asset.characteristics.constructionList
            .filter(
              (c) =>
                c.constructionDateType === "PERMIT SUBMISSION DATE" ||
                c.constructionDateType === "CONSTRUCTION DATE"
            )
            .map((construction) => construction.constructionDate)
        ),
      includeNodeWhenValueIsEmpty: true,
    }),
    "project|created_at": new FilterRule({
      targetEntity: Project,
      targetAttrs: ["createdAt"],
      getCollection: (data) => {
        const date = data?.currentProject?.createdAt;
        return date ? [date.split("T")[0]] : [];
      },
      includeNodeWhenValueIsEmpty: true,
    }),
  },

  // Static rules mapping
  staticRules: [
    new FilterRule({
      sourceEntity: RapsotecNodeAmos,
      sourceAttr: "missionCodesList",
      targetEntity: ProjectConfig,
      targetAttrs: ["submissions"],
      getCollection: (data) => [data.currentProjectConfig],
      apply: (missionCodesList, collection) =>
        missionCodesList.some((code) =>
          collection.some((c) => c.submissions.includes(code))
        ),
      mapping: { S: "HBAG-1", HAND: "HZAA-1" },
    }),
    new FilterRule({
      sourceEntity: RapsotecNodeAmos,
      sourceAttr: "destinationCodesList",
      targetEntity: BuildingCharacteristic,
      targetAttrs: [
        "erpMainType",
        "erpSecondaryTypeList",
        "erpCategory",
        "isErt",
        "isErp",
        "isIgh",
        "isIhm",
        "isResidential",
      ],
      getCollection: (data) =>
        data.buildingAssets.map((asset) => asset.characteristics),
      apply: codesMappingRule,
      mapping: CT_DESTINATION_CODES_MAPPING,
    }),
    new FilterRule({
      sourceEntity: RapsotecNodeAmos,
      sourceAttr: "typeCodesList",
      targetEntity: BuildingCharacteristic,
      targetAttrs: [
        "erpMainType",
        "erpSecondaryTypeList",
        "ighTypeList",
        "individualResidentialFamily",
        "collectifResidentialFamilyList",
        "isParkingArea",
        "isErt",
        "isErp",
        "isResidential",
      ],
      getCollection: (data) =>
        data.buildingAssets.map((asset) => asset.characteristics),
      apply: codesMappingRule,
      mapping: CT_TYPE_CODES_MAPPING,
    }),
  ],
};
