import socioGrpcClient from "@/setup/socioGrpcClient";
import { Relation } from "@/models/Relation";

const modelLinkerService = socioGrpcClient.model_linker.model_linker_svc;

const {
  AppRetrieveRequest,
  AppControllerPromiseClient,
  RelationControllerPromiseClient,
  RelationRequest,
  BulkCreateOrUpdateRelationRequest,
  BulkRemoveRequest,
  MatchIdsRequest,
  RelationListRequest,
} = modelLinkerService;

export const buildRelationRequest = ({
  appServiceId,
  appEntityName,
  appEntityId,
  targetService,
  targetEntityName,
  targetEntityId,
  linkType,
}) => {
  const relation = new RelationRequest();
  relation.setAppServiceId(appServiceId);
  relation.setAppEntityName(appEntityName);
  relation.setAppEntityId(appEntityId);
  relation.setTargetService(targetService);
  relation.setTargetEntityName(targetEntityName);
  relation.setTargetEntityId(targetEntityId);
  relation.setLinkType(linkType);
  return relation;
};

const state = {
  appId: null,
  appInfo: null,
};

const getters = {
  getAppId: (state) => {
    return state.appId;
  },
};

const actions = {
  async fetchApp({ commit }, { serviceId }) {
    try {
      const request = new AppRetrieveRequest();
      request.setServiceId(serviceId);
      const response = await AppControllerPromiseClient.retrieve(request, {});
      const result = response.toObject();
      commit("SET_APP", result);
    } catch (e) {
      console.error(
        `Cannot fetch app info: ${serviceId} from model-linker microservice`
      );
      throw e;
    }
  },

  async bulkCreateOrUpdateRelations(_, { relations, clearExisting = false }) {
    try {
      const request = new BulkCreateOrUpdateRelationRequest();
      request.setRelationsList(relations);
      request.setClearExisting(clearExisting);
      await RelationControllerPromiseClient.bulkCreateOrUpdate(request, {});
    } catch (e) {
      console.error("Cannot bulk create - update relations");
      throw e;
    }
  },

  async bulkRemoveRelations(_, { appServiceId, entityName, entityId }) {
    try {
      const request = new BulkRemoveRequest();
      request.setAppServiceId(appServiceId);
      request.setEntityName(entityName);
      request.setEntityId(entityId);
      await RelationControllerPromiseClient.bulkRemoveFromEntity(request, {});
    } catch (e) {
      console.error(`Cannot bulk remove relations : ${entityName} ${entityId}`);
      throw e;
    }
  },

  async findEntitiesByTargetIds(
    _,
    {
      appServiceId,
      entityName,
      targetIds,
      targetEntityName = null,
      targetService = null,
      matchAllRelations = false,
    }
  ) {
    const request = new MatchIdsRequest();
    request.setUuidsList(targetIds);
    request.setMatchAllRelations(matchAllRelations);

    const filters = {
      app_service_id: appServiceId,
      app_entity_name: entityName,
      ...(targetEntityName && { target_entity_name: targetEntityName }),
      ...(targetService && { target_service: targetService }),
    };

    const metadata = {
      filters: JSON.stringify(filters),
    };

    try {
      const response =
        await RelationControllerPromiseClient.findEntitiesByTargetIds(
          request,
          metadata
        );
      const results = response.toObject().resultsList;
      return results.map((r) => r.appEntityId);
    } catch (e) {
      console.error("Cannot make call to find entities by target ids");
      throw e;
    }
  },

  async fetchEntityRelations(_, { filters, populateORM = false }) {
    const request = new RelationListRequest();

    const metadata = {
      filters: JSON.stringify(filters),
    };

    const response = await RelationControllerPromiseClient.list(
      request,
      metadata
    );

    const result = response.toObject();
    const relations = result.resultsList || [];

    if (populateORM) {
      await Relation.insert({
        data: relations,
      });
    }
    return relations;
  },
};
const mutations = {
  SET_APP: function (state, app) {
    state.appId = app.uuid;
    state.appInfo = app;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
