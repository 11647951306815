import {
  CtNodeObservation,
  Disposition,
  Observation,
  StatementType,
  DocumentReview,
} from "@/models";

import { PerimeterFactory } from "@/utils/aos";
import {
  makeSubObservationModelFromReferencialId,
  setObservationStatementToPush,
} from "@/utils/observation";
import { buildActionRequestForConnect } from "@/utils/action";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import { useRxdbCollection } from "@/composables/useRxdbCollection";
import { observtionEditableFields } from "@/composables/useObservationDashboardValidation";

export const actions = {
  initDraftObservation(
    { state, commit, rootGetters },
    {
      analyticalAxis,
      perimeter,
      mode = "SINGLE",
      origin,
      metadata = {},
      amosDocumentsList,
      functionalRequirementsList,
      ctComment,
      refCode,
      dispositionTypesList,
      typologyTypesList,
      ctNodesList,
    }
  ) {
    commit("INIT_DRAFT_OBSERVATION", {
      amosDocumentsList,
      functionalRequirementsList,
      ctComment,
      refCode,
      analyticalAxis,
      origin,
      metadata,
      dispositionTypesList,
      typologyTypesList,
      ctNodesList,
      user: rootGetters["user/getCurrentUser"],
    });
    commit(`SET_${mode}_PERIMETER`, perimeter);
    return state.observation;
  },

  setDraftObservationFromInstance({ state }, observationUuid) {
    const instance = Observation.find(observationUuid);
    state.observation = instance;
  },

  reset({ state }, initialObservation) {
    if (!state.observation) return;
    if (!initialObservation) {
      const draftInstance = Observation.find(state.observation.uuid);
      draftInstance.$delete();
      state.observation = null;
      return;
    }

    const initialData = cloneDeep(initialObservation);
    observtionEditableFields.forEach(([field]) => {
      state.observation[field] = initialData[field];
    });
    const { smallestQuotationValue, smallestQuotationStatement } = initialData;
    state.observation.smallestQuotationValue = smallestQuotationValue;
    state.observation.smallestQuotationStatement = smallestQuotationStatement;
    state.observation.$save();
    state.observation = null;
  },

  async saveObservation({ state, dispatch, rootGetters }) {
    const {
      uuid,
      analyticalAxis,
      aosObservablesList,
      riskAssessment,
      amosDocumentsList,
      dispositionTypesList,
      typologyTypesList,
      ctNodesList,
      functionalRequirementsList,
      origin,
      ctComment,
      refCode,
      metadata,
      createdAt,
      createdBy,
      createdByEmail,
      createdByFirstname,
      createdByLastname,
      modifiedBy,
      modifiedByEmail,
      modifiedByFirstname,
      modifiedByLastname,
    } = state.observation;

    const {
      statementsData,
      statementReq,
      lowestQuotationValue,
      statementWithLowestQuotationValue,
    } = await setObservationStatementToPush(uuid);

    // Get actions data
    const { execOnCollection: actionCollection } = useRxdbCollection("action");
    const selector = { observationsList: uuid };
    const rxDocs = await actionCollection((c) => c.find({ selector }).exec());
    const actionsData = rxDocs.map((item) => item.toJSON());
    const actionsReq = actionsData.map(buildActionRequestForConnect);

    const currentUser = rootGetters["user/getCurrentUser"];

    const isPicassoCT =
      rootGetters["project/getIsPicassoVisit"] && currentUser.isCT();

    const userInfo = {
      createdBy,
      createdByEmail,
      createdByFirstname,
      createdByLastname,
      modifiedBy,
      modifiedByEmail,
      modifiedByFirstname,
      modifiedByLastname,
    };

    const offlineCreatedObservation = {
      ...userInfo,
      uuid,
      amosDocumentsList,
      analyticalAxis,
      aosObservablesList,
      createdAt: createdAt || dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
      updatedAt: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
      ctComment,
      dispositionTypesList,
      functionalRequirementsList,
      isArchived: false,
      isValidated: isPicassoCT,
      metadata,
      origin,
      riskAssessment: riskAssessment ?? "",
      perimeter: "",
      actionsCount: 0,
      smallestQuotationStatement:
        StatementType.find(statementWithLowestQuotationValue?.statementType)
          ?.label ?? "",
      smallestQuotationValue: lowestQuotationValue?.value ?? -1,
      statementsCount: statementsData?.length ?? 0,
      typologyTypesList: [],
      createdOrUpdatedOffline: true,
      refCode,
      synchronisationState: state.defaultCreationState,
      /* offlineRequestbuildMetadata holds 
        - the request needed to complete the push.
           When the push is triggered online, 
           it will use the requests to perform the complete assign request with nested request
        - info if the observation is made as a PicassoCtReview
      */
      offlineRequestBuildMetaData: {
        statementReq,
        actionsReq,
        // node ids
        documentReviews: makeSubObservationModelFromReferencialId({
          modelClass: DocumentReview,
          referencialIdKey: "typologyType",
          referencialIds: typologyTypesList,
          observationUuid: uuid,
        }),
        dispositions: makeSubObservationModelFromReferencialId({
          modelClass: Disposition,
          referencialIdKey: "dispositionType",
          referencialIds: dispositionTypesList,
          observationUuid: uuid,
        }),
        ctNodeObservations: makeSubObservationModelFromReferencialId({
          modelClass: CtNodeObservation,
          referencialIdKey: "ctNode",
          referencialIds: ctNodesList,
          observationUuid: uuid,
        }),
      },
    };

    const { execOnCollection: observationCollection } =
      useRxdbCollection("observation");
    Observation.insert({ data: [offlineCreatedObservation] });

    const upserted = await observationCollection(
      (c) => c.upsert(cloneDeep(offlineCreatedObservation)),
      true
    );

    const { error = [] } = upserted;
    if (error.length) {
      console.warn("Error encountered while upserting observation", error);
    }

    // persist uuid on the user_perimeter for observation and observationStatement.
    // this allow offline to retrieve models (for exemple, models created offline and navigator is closed before a resync)
    const { execOnCollection: perimeterCollection } =
      useRxdbCollection("user_perimeter");
    const perimeter = await perimeterCollection((c) =>
      c
        .findOne({
          selector: {
            projectId: rootGetters["project/getProjectUuid"],
            userId: null,
          },
        })
        .exec()
    );
    await perimeter.incrementalUpdate({
      $push: {
        observationUuids: offlineCreatedObservation.uuid,
        observationStatementUuid: {
          $each: statementsData.map(({ uuid }) => uuid),
        },
      },
    });

    if (rootGetters["pin/getDraftPin"]) {
      await dispatch(
        "pin/createDraftPin",
        { observationUuid: offlineCreatedObservation.uuid },
        { root: true }
      );
    }

    state.observation = null;
  },

  updatePerimeter({ commit }, perimeter) {
    const factory = new PerimeterFactory(perimeter);
    const aosObservablesList = factory.toAmosMulti().getUuids();
    commit("PARTIAL_UPDATE_DRAFT_OBSERVATION", { aosObservablesList });
  },
};
