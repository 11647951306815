const state = {
  drawer: false,
  drawerWidth: 256,
  promptPwa: null,
};

const getters = {
  mini: (state) => {
    return state.drawer;
  },
  drawerWidth: (state) => {
    return state.drawerWidth;
  },
  promptPwa: (state) => {
    return state.promptPwa;
  },
};

const actions = {
  setNavDrawer: ({ commit }) => {
    commit("navDrawer");
  },
  setPromptPwa: ({ commit }, deferredPrompt) => {
    commit("promptPwa", deferredPrompt);
  },
};

const mutations = {
  navDrawer: (state) => {
    state.drawer = !state.drawer;
    state.drawerWidth = 256;
  },
  promptPwa: (state, deferredPrompt) => {
    state.promptPwa = deferredPrompt;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
