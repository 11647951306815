import axios from "axios";
import { compressFile } from "@/utils/image";

const restDocumentClient = axios.create({
  baseURL: process.env.VUE_APP_U_DOCUMENT_BACK_URL,
  headers: {},
});

/**
 * @param {FormData} docFile
 * @param {"put"|"post"} method
 * @param {boolean} [compress]
 * @returns {Promise}
 */
restDocumentClient.uploadBimDocument = async (
  docFile,
  method,
  compress = true
) => {
  const file = docFile.get("file");
  if (compress && file?.type.includes("image")) {
    docFile.set(
      "file",
      await compressFile(file, {
        maxSizeMB: 1,
      })
    );
  }
  return await (method === "post"
    ? restDocumentClient.post
    : restDocumentClient.put)("/documents/upload_document/", docFile, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const initDocumentAuth = (accessToken) => {
  restDocumentClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

export default restDocumentClient;
export { restDocumentClient, initDocumentAuth, compressFile };
