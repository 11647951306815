const state = {
  // true by default. Loading of data for visioneuse is made asynchronously in a replicationCollection.
  // rxdbWorkerMessageHandler will set this to "false" by mutations
  loading: true,
  bimDataTokens: {},
  pdfExporting: false,
  bimdataArchiveDownloading: false,
};

const getters = {
  getBimDataToken: (state) => (originId) => {
    return state.bimDataTokens[originId];
  },
};

const mutations = {
  SET_LOADING_STATE: (state, value) => {
    state.loading = value;
  },
  SET_BIMDATA_TOKEN: (state, BimDataList) => {
    if (!BimDataList?.length) return;
    const result = BimDataList.reduce((acc, curr) => {
      acc[curr.originId] = curr.token;
      return acc;
    }, {});
    state.bimDataTokens = result;
  },
  SET_PDF_EXPORTING: (state, value) => {
    state.pdfExporting = value;
  },
  SET_BIMDATA_ARCHIVE_DOWNLOADING: (state, value) => {
    state.bimdataArchiveDownloading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
