import { TechnicalAdviceReferencial } from "@/models";
import { socioGrpcClient } from "@/setup/socioGrpcClient";

const {
  amos_back: { amos_admin: amos_admin_api },
} = socioGrpcClient;

const state = {
  amosAdminDataFetched: false,
  technicalAdviceReferencialNatureCount: 0,
  referencialCodeCount: 0,
};

const getters = {
  isAmosAdminDataFetched: (state) => state.amosAdminDataFetched,

  getTechnicalReferencials: () => {
    return TechnicalAdviceReferencial.all();
  },
  getNatureCodes: () => (item) => {
    const { nature } = item;
    return TechnicalAdviceReferencial.query().where("nature", nature).get();
  },
  getCodeLabel: () => (item) => {
    const {
      code: { code },
    } = item;
    if (!code) return "";
    const { label } = TechnicalAdviceReferencial.query()
      .where("code", code)
      .first();
    return label;
  },
};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! No logic for insert in rxdb
  async fetchAllTechnicalReferencialData(
    { commit, getters },
    { metadata = {} } = {}
  ) {
    if (!getters.isAmosAdminDataFetched) {
      const request =
        new amos_admin_api.TechnicalAdviceReferencialFetchAllRequest();

      const response =
        await amos_admin_api.TechnicalAdviceReferencialControllerPromiseClient.fetchAll(
          request,
          metadata
        );
      await TechnicalAdviceReferencial.insertOrUpdate({
        data: response.toObject().resultsList,
      });
      commit("SET_ADMIN_AMOS_DATA_AS_FETCHED");
    }
  },
};

const mutations = {
  SET_ADMIN_AMOS_DATA_AS_FETCHED: (state) =>
    (state.amosAdminDataFetched = true),
};
export default {
  state,
  getters,
  actions,
  mutations,
};
