import { socioGrpcClient } from "@/setup/socioGrpcClient";
import Mission from "@/models/Mission";
import SubMission from "@/models/SubMission";
import { useRxdbCollection } from "../../composables/useRxdbCollection";
import { convertMapToArray } from "@/rxdb/utils.js";
const state = {};

const getters = {};

const actions = {
  async retrieveMission(_, missionCode) {
    const request =
      new socioGrpcClient.amos_back.projects.MissionRetrieveRequest();
    request.setCode(missionCode);

    const response =
      await socioGrpcClient.amos_back.projects.MissionControllerPromiseClient.retrieve(
        request,
        {}
      );

    const missionData = response.toObject();

    await Mission.insertOrUpdate({
      data: missionData,
    });
    return Mission.find(missionCode);
  },

  // MS - INFO - 24/07/24 - Caution ! No logic for insert in rxdb
  async fetchSubMissions(_, metadata = {}) {
    const { execOnCollection } = useRxdbCollection("sub_mission");
    const request =
      new socioGrpcClient.amos_back.projects.SubMissionListRequest();
    try {
      const response =
        await socioGrpcClient.amos_back.projects.SubMissionControllerPromiseClient.list(
          request,
          {
            filters: JSON.stringify(metadata.filters),
            pagination: JSON.stringify(metadata.pagination),
          }
        );

      const responseResultsList = response.toObject().resultsList;
      if (!responseResultsList.length) return;

      await SubMission.insert({
        data: responseResultsList,
      });
      const keysToIgnore = ["createdAt", "updatedAt", "rduIndex"];
      const dataToInsert = responseResultsList.map((obj) => {
        keysToIgnore.forEach((key) => {
          if (obj[key]) {
            delete obj[key];
          }
        });
        return obj;
      });
      await execOnCollection((c) => c.bulkInsert(dataToInsert));
    } catch (error) {
      let response = [];
      if (metadata.filters?.code__in) {
        response = await execOnCollection((c) =>
          c.findByIds(metadata.filters.code__in.split(",")).exec()
        );
        response = convertMapToArray(response);
      } else response = await execOnCollection((c) => c.find().exec());
      if (response.length) {
        SubMission.insert({
          data: response,
        });
        await execOnCollection((c) => c.bulkInsert(response));
      }
    }
  },

  async resetState() {
    await Promise.all([Mission.deleteAll(), SubMission.deleteAll()]);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
