import StoreyAsset from "@/models/StoreyAsset";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { setNestedRequest } from "@/utils/request";
import requestFactory from "../factory";
import { cloneDeep } from "lodash";

const client = socioGrpcClient.amos_back.aos.StoreyControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;

export function StoreyCharacteristics(data = {}) {
  return {
    uuid: data.uuid,
    altimetryNgf: data.altimetryNgf,
    altimetryReference: data.altimetryReference,
    tonnage: data.tonnage,
  };
}

const state = {
  storeyData: StoreyCharacteristics(),
  storeyDataInitCopy: StoreyCharacteristics(),
};

const getters = {};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! StoreyAsset pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  fetchStoreys: async (_, { metadata = {}, listAll = false }) => {
    const request = new socioGrpcClient.amos_back.aos.StoreyListRequest();
    const response = listAll
      ? await client.utils.listAllObjects(request, metadata)
      : await client.list(request, metadata);

    const data = listAll ? response : response.toObject().resultsList;
    await StoreyAsset.insert({
      data,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.StoreyRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    StoreyAsset,
    socioGrpcClient.amos_back.aos.StoreyCharacteristicsPartialUpdateRequest,
    client,
    ["code"],
    (data) =>
      setNestedRequest(
        data,
        "surfaceList",
        socioGrpcClient.amos_back.aos.SurfaceRequest,
        ["code"]
      )
  ),
  updateCode: async (_, { uuid, code }) => {
    const request = new socioGrpcClient.amos_back.aos.StoreyUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    StoreyAsset.update({
      where: uuid,
      data: { code },
    });
    return (await client.updateCode(request, {})).toObject();
  },
  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "StoreyAsset"
  ),
  delete: requestFactory.actions.deleteFactory(
    client,
    socioGrpcClient.amos_back.aos.DeleteRequest,
    "StoreyAsset"
  ),
};

const mutations = {
  SET_STOREY_DATA: (state, data) => {
    state.storeyData = data;
  },
  SET_STOREY_DATA_COPY: (state) => {
    state.storeyDataInitCopy = cloneDeep(state.storeyData);
  },
  SET_STOREY_DATA_TO_SAVE: (state, data) => {
    state.storeyData = Object.assign(
      StoreyCharacteristics(),
      state.storeyData,
      StoreyCharacteristics(data)
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
