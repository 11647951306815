import { socioGrpcClient } from "@/setup/socioGrpcClient";
import SiteAsset from "@/models/SiteAsset";
import { setNestedRequest } from "@/utils/request";
import requestFactory from "../factory";

const client = socioGrpcClient.amos_back.aos.SiteControllerPromiseClient;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;

const getters = {};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! SiteAsset pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  fetchSites: async (_, { aosSites = [], metadata = {}, listAll = false }) => {
    const request = new socioGrpcClient.amos_back.aos.SiteListRequest();
    request.setAosItemsList(aosSites);

    const response = listAll
      ? await client.utils.listAllObjects(request, metadata)
      : await client.list(request, metadata);

    const data = listAll ? response : response.toObject().resultsList;

    const transformedData = data.map((s) => ({ ...s, tagsData: s.tagsList }));
    await SiteAsset.insertOrUpdate({
      data: transformedData,
    });
    return transformedData;
  },

  async retrieveCharacteristics(_, assetUuid) {
    const request =
      new socioGrpcClient.amos_back.aos.SiteRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  partialUpdateCharacteristics: requestFactory.actions.partialUpdateFactory(
    SiteAsset,
    socioGrpcClient.amos_back.aos.SiteCharacteristicsPartialUpdateRequest,
    client,
    ["code"],
    (data) =>
      setNestedRequest(
        data,
        "surfaceList",
        socioGrpcClient.amos_back.aos.SurfaceRequest,
        ["code"]
      )
  ),
  updateCode: async (_, { uuid, code }) => {
    const request = new socioGrpcClient.amos_back.aos.SiteUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    SiteAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },

  exportInventory: requestFactory.actions.exportFactory(
    socioGrpcClient.amos_back.aos.SiteControllerPromiseClient,
    socioGrpcClient.amos_back.aos.ExportRequest,
    "inventory"
  ),

  delete: requestFactory.actions.deleteFactory(
    client,
    socioGrpcClient.amos_back.aos.DeleteRequest,
    "SiteAsset"
  ),
};

export default {
  namespaced: true,
  actions,
  getters,
};
