import { socioGrpcClient } from "@/setup/socioGrpcClient";
import {
  ObservationStatement,
  Observation,
  ObservationHistory,
  Action,
  BIMDataPin,
} from "@/models/";
import requestFactory from "../factory";
import { uniqBy } from "lodash";
import { useRxdbCollection } from "@/composables/useRxdbCollection";
import store from "@/store";

const {
  amos_back: { observation: observationApi },
} = socioGrpcClient;

const state = {
  loading: true,
  queryLoading: true,
  dispositionLoading: true,
  documentReviewLoading: true,
  ctNodeObservationLoading: true,
  actionsLoading: true,
  activeObservationPerimeter: null,
  documentIdsToUrls: {},
};

const getters = {
  getObservations: () => {
    return Observation.query()
      .where("isArchived", false)
      .orderBy("createdAt", "desc")
      .get();
  },
  getActiveObservationPerimeter: (state) => {
    return state.activeObservationPerimeter;
  },
  getInfoPerimeterTitleText: () => (observationIds) => {
    if (!observationIds) return "";
    if (observationIds.length === 1) {
      const observation = Observation.find(observationIds[0]);
      const perimeterItems = observation?.makePerimeter()?.items;
      if (perimeterItems?.length < 2) {
        return perimeterItems[0]?.items[0]?.designation ?? "";
      }
      return perimeterItems?.length ? `Multi - ${perimeterItems.length}` : "";
    }

    const observations = Observation.findIn(observationIds);
    const distinctPerimeterItems = uniqBy(
      observations.flatMap((obs) => obs.makePerimeter()?.items),
      (item) => item.uuid
    );

    if (distinctPerimeterItems.length === 1) {
      return distinctPerimeterItems[0]?.items[0]?.designation ?? "";
    }
    return distinctPerimeterItems?.length
      ? `Multi - ${distinctPerimeterItems.length}`
      : "";
  },
  getLastObservationId: (state, getters) => getters.getObservations[0]?.uuid,

  getAosObservarbleObservationsCountMap: () => {
    let aosObservarbleObservationsCountMap = {};
    const observations = Observation.all();
    observations.forEach((observation) => {
      if (!observation.aosObservablesList) return;
      observation.aosObservablesList.forEach((aosObservable) => {
        aosObservarbleObservationsCountMap[aosObservable] =
          (aosObservarbleObservationsCountMap[aosObservable] || 0) + 1;
      });
    });
    return aosObservarbleObservationsCountMap;
  },
};

const actions = {
  async deleteObservation({ dispatch }, observationUuid) {
    const { execOnCollection } = useRxdbCollection("observation");
    const observation = await execOnCollection((c) =>
      c.findOne(observationUuid).exec()
    );
    if (!observation) {
      console.warn("No observation found locally", observationUuid);
    }
    const isOfflineObservation =
      !!observation?.toJSON().createdOrUpdatedOffline;

    try {
      await Observation.delete(observationUuid);

      if (store.state.appIsOnline) {
        await dispatch("doDeleteObservation", observationUuid);
        // if online call worked, we can assume it must be deleted in front
        await execOnCollection((c) => c.bulkRemove([observationUuid]));
        return;
      }

      if (isOfflineObservation) {
        // else only delete if it s an offline observation
        await execOnCollection((c) => c.bulkRemove([observationUuid]));
        const { execOnCollection: pinCollection } =
          useRxdbCollection("b_i_m_data_pin");
        const pin = await pinCollection((c) =>
          c
            .findOne({
              selector: {
                observation: observationUuid,
              },
            })
            .exec()
        );

        await pinCollection((c) => c.bulkRemove([pin.uuid]));
      } else {
        await observation.incrementalUpdate({
          $set: { isArchived: true, createdOrUpdatedOffline: true },
        });

        // Delete associateds pins
        await BIMDataPin.query()
          .where((pin) => pin.observation === observationUuid)
          .delete();
        const { execOnCollection: pinCollection } =
          useRxdbCollection("b_i_m_data_pin");
        const pin = await pinCollection((c) =>
          c
            .findOne({
              selector: {
                observation: observationUuid,
              },
            })
            .exec()
        );

        await pin.incrementalUpdate({
          $set: { isArchived: true, offlineCreated: true },
        });
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  specificPartialUpdateFactory:
    requestFactory.actions.specificPartialUpdateFactory(
      Observation,
      observationApi.ObservationPartialUpdateRequest,
      observationApi.ObservationControllerPromiseClient
    ),

  async archiveObservation({ dispatch }, uuid) {
    await Observation.delete(uuid);

    if (store.state.appIsOnline) {
      await dispatch("specificPartialUpdateFactory", {
        uuid,
        isArchived: true,
      });
    } else {
      const { execOnCollection } = useRxdbCollection("observation");
      const observation = await execOnCollection((c) => c.findOne(uuid).exec());
      await observation.incrementalUpdate({
        $set: { isArchived: true, createdOrUpdatedOffline: true },
      });
    }
  },

  async doDeleteObservation(_, observationUuid) {
    const request = new observationApi.ObservationDestroyRequest();
    request.setUuid(observationUuid);
    await observationApi.ObservationControllerPromiseClient.destroy(
      request,
      {}
    );
  },

  async setObservationRisk(_, { observationUuid, riskLevel }) {
    const request = new observationApi.ObservationSetRiskLevelRequest();
    request.setUuid(observationUuid);
    request.setRiskAssessment(riskLevel);

    const response =
      await observationApi.ObservationControllerPromiseClient.setRiskLevel(
        request,
        {}
      );

    const { riskAssessment } = response.toObject();
    await Observation.update({
      where: (record) => record.uuid === observationUuid,
      data: { riskAssessment },
    });
  },

  async retrieveObservationHistory(_, observationUuid) {
    if (!observationUuid) return;

    const request = new observationApi.ObservationRetrieveHistoryRequest();
    request.setUuid(observationUuid);

    try {
      const response =
        await observationApi.ObservationControllerPromiseClient.retrieveHistory(
          request,
          {}
        );
      const items = response.toObject();

      const results = items.resultsList.map((item) => {
        return {
          historyDate: item.historyDate,
          reportDate: item.reportDate,
          report: item.report,
          documentUuid: item.documentUuid,
          reportName: item.reportName,
          observation: item.uuid,
          modifiedBy: item.modifiedBy,
        };
      });

      await ObservationHistory.create({ data: results });
    } catch (error) {
      return;
    }
  },

  async resetState() {
    await Promise.all([
      Observation.deleteAll(),
      ObservationStatement.deleteAll(),
      Action.deleteAll(),
    ]);
  },

  exportObservations: requestFactory.actions.exportFactory(
    observationApi.ObservationControllerPromiseClient,
    observationApi.ExportRequest,
    "observation"
  ),
};

const mutations = {
  SET_OBSERVATION_LOADING: (state, value) => (state.loading = value),
  SET_QUERY_LOADING: (state, value) => (state.queryLoading = value),
  SET_DISPOSITION_LOADING: (state, value) => (state.dispositionLoading = value),
  SET_ACTIONS_LOADING: (state, value) => (state.actionsLoading = value),
  SET_DOC_REVIEW_LOADING: (state, value) =>
    (state.documentReviewLoading = value),
  SET_CT_NODE_OBSERVATION_LOADING: (state, value) =>
    (state.ctNodeObservationLoading = value),
  SET_ACTIVE_OBSERVATION_PERIMETER: (state, value) =>
    (state.activeObservationPerimeter = value),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
