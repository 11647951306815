import { requestFactory } from "@socotec.io/socio-vue-components";
import socioGrpcClient from "@/setup/socioGrpcClient";
import i18n from "@/setup/i18n";
import Contributor from "@/models/Contributor";

const client = socioGrpcClient.contributors.contributors;

const transformContributor = (contributor) => ({
  ...contributor,
  role: {
    ...contributor.companyProjectRole.role,
    name: i18n.t(`contributors.${contributor.companyProjectRole.role.name}`),
  },
});

const transformListResponse = (r) => r.resultsList.map(transformContributor);
const excludeFields = [
  "metadata",
  "$id",
  "createdAt",
  "updatedAt",
  "project",
  "role",
  "batchesList",
  "company",
  "isLocked",
];

const state = {
  contributorsCount: 0,
};

const getters = {
  getContributorsCount: (state) => {
    return state.contributorsCount;
  },
  getProjectContributors: (state, getters, rootState) => {
    if (!rootState.project.currentProjectUuid) {
      return [];
    }
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query.where("projectId", rootState.project.currentProjectUuid);
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("actorTypologies")
      .orderBy("userLastName")
      .get()
      .map((contributor) => {
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
        };
      });
  },
  getProjectContributorsExternal: (state, getters, rootState) => {
    if (!rootState.project.currentProjectUuid) {
      return [];
    }
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.project.currentProjectUuid)
          .whereHas("role", (query) => {
            query.where("roleType", "EXTERNAL");
          });
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("actorTypologies")
      .orderBy("userLastName")
      .get()
      .map((contributor) => {
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
        };
      });
  },
  getContributorByUuid: (state, getters, rootState) => (uuid) => {
    if (!rootState.project.currentProjectUuid) {
      return null;
    }

    const contributor = Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query.where("projectId", rootState.project.currentProjectUuid);
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("actorTypologies")
      .find(uuid);

    if (contributor) {
      return {
        ...contributor,
        role: contributor.companyProjectRole?.role,
        company: contributor.companyProjectRole?.company,
      };
    }
    return null;
  },
  getProjectContributorsInternal: (state, getters, rootState) => {
    if (!rootState.project.currentProjectUuid) {
      return [];
    }
    return Contributor.query()
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", rootState.project.currentProjectUuid)
          .whereHas("role", (query) => {
            query.where((query) => query.roleType !== "EXTERNAL");
          });
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("actorTypologies")
      .orderBy("userLastName")
      .get()
      .map((contributor) => {
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
        };
      });
  },
  getIsContributorCurrentUser:
    (state, getters, rootState, rootGetters) => (contributor) => {
      const user = rootGetters["user/getCurrentUser"];
      return contributor?.usermanagementUuid === user?.uuid;
    },
};

const actions = {
  fetchContributorsForDataTable: requestFactory.actions.list({
    ModelClass: Contributor,
    grpcListRequest: client.ContributorCompanyProjectRoleLightListRequest,
    grpcListMethod: client.ContributorControllerPromiseClient.list,
    insertMode: "create",
    handleResponseData: transformListResponse,
    onItemsInserted: (ctx, res) =>
      ctx.commit("SET_CONTRIBUTORS_COUNT", res.count),
  }),

  fetchContributors: requestFactory.actions.list({
    ModelClass: Contributor,
    grpcListRequest: client.ContributorCompanyProjectRoleLightListRequest,
    grpcListMethod: client.ContributorControllerPromiseClient.list,
    insertMode: "insertOrUpdate",
    handleResponseData: transformListResponse,
  }),

  async createContributor({ commit, getters, rootGetters }, contributor) {
    const request = socioGrpcClient.javascriptToRequest(
      client.ContributorInputRequest,
      contributor,
      excludeFields
    );
    const response = await client.ContributorControllerPromiseClient.create(
      request,
      {}
    );

    const newContributor = transformContributor(response.toObject());
    commit("ADD_CONTRIBUTORS_COUNT", 1);
    await Contributor.insert({
      data: newContributor,
    });

    // INFO - S.C - 2024-15-02 - Create the role of the user if the contributor is the current user
    if (getters.getIsContributorCurrentUser(contributor)) {
      rootGetters["user/getCurrentUser"].updateProjectRole(
        rootGetters["project/getCurrentProject"]?.uuid,
        newContributor?.companyProjectRole?.roleId
      );
    }

    return newContributor.uuid;
  },
  async updateContributor({ getters, rootGetters }, contributor) {
    const request = socioGrpcClient.javascriptToRequest(
      client.ContributorInputRequest,
      contributor,
      excludeFields
    );
    const response = await client.ContributorControllerPromiseClient.update(
      request,
      {}
    );

    const newContributor = transformContributor(response.toObject());
    await Contributor.insertOrUpdate({
      data: newContributor,
    });

    // INFO - S.C - 2024-15-02 - Update the role of the user if the contributor is the current user
    if (getters.getIsContributorCurrentUser(contributor)) {
      rootGetters["user/getCurrentUser"].updateProjectRole(
        rootGetters["project/getCurrentProject"]?.uuid,
        newContributor?.companyProjectRole?.roleId
      );
    }

    return newContributor.uuid;
  },
  async deleteContributor({ commit, getters, rootGetters }, contributor) {
    const request = new client.ContributorDestroyRequest();

    request.setUuid(contributor.uuid);

    await client.ContributorControllerPromiseClient.destroy(request, {});

    commit("ADD_CONTRIBUTORS_COUNT", -1);

    // INFO - S.C - 2024-15-02 - Remove the role of the user if the contributor is the current user
    if (getters.getIsContributorCurrentUser(contributor)) {
      rootGetters["user/getCurrentUser"].updateProjectRole(
        rootGetters["project/getCurrentProject"]?.uuid
      );
    }

    await Contributor.delete(contributor.uuid);
  },
  async searchContributor(context, text) {
    const metadata = {
      filters: JSON.stringify({ email: text }),
    };
    const request = new client.ContributorCompanyProjectRoleLightListRequest();
    const response = await client.ContributorControllerPromiseClient.list(
      request,
      metadata
    );
    const contributors = response.toObject().resultsList;
    return contributors.map((contributor) => new Contributor(contributor));
  },
  async searchProjetContributorExternal(context, params) {
    return Contributor.query()
      .where((user) => {
        return user.userEmail.includes(params.email);
      })
      .whereHas("companyProjectRole", (query) => {
        query
          .where("projectId", params.projectUuid)
          .whereHas("role", (query) => {
            query.where("roleType", "EXTERNAL");
          });
      })
      .with("companyProjectRole.role")
      .with("companyProjectRole.company")
      .with("actorTypologies")
      .orderBy("userLastName")
      .get()
      .map((contributor) => {
        return {
          ...contributor,
          role: contributor.companyProjectRole?.role,
          company: contributor.companyProjectRole?.company,
        };
      });
  },
};
const mutations = {
  SET_CONTRIBUTORS_COUNT: (state, newTotal) => {
    state.contributorsCount = newTotal;
  },
  ADD_CONTRIBUTORS_COUNT: (state, number) => {
    state.contributorsCount += number;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
