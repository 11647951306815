import { v4 as uuidv4 } from "uuid";
import { Observation } from "@/models";
import { forIn } from "lodash";
import { PerimeterFactory } from "@/utils/aos";

export const mutations = {
  INIT_DRAFT_OBSERVATION: (state, payload) => {
    state.observation = new Observation();
    // base data
    state.observation.uuid = uuidv4();
    state.observation.origin = payload.origin;
    state.observation.createdAt = new Date().toISOString();
    state.observation.updatedAt = new Date().toISOString();

    // editable data
    state.observation.analyticalAxis = payload.analyticalAxis;
    state.observation.metadata = payload.metadata;
    state.observation.amosDocumentsList = payload.amosDocumentsList;
    state.observation.functionalRequirementsList =
      payload.functionalRequirementsList;
    state.observation.ctComment = payload.ctComment;
    state.observation.refCode = payload.refCode;

    // node related data
    state.observation.dispositionTypesList = payload.dispositionTypesList;
    state.observation.typologyTypesList = payload.typologyTypesList;
    state.observation.ctNodesList = payload.ctNodesList;

    // user data
    state.observation.createdBy = payload.user.usermanagementUuid;
    state.observation.modifiedBy = payload.user.usermanagementUuid;
    state.observation.createdByFirstname = payload.user.firstName;
    state.observation.createdByLastname = payload.user.lastName;
    state.observation.createdByEmail = payload.user.email;
    state.observation.modifiedByFirstname = payload.user.firstName;
    state.observation.modifiedByLastname = payload.user.lastName;
    state.observation.modifiedByEmail = payload.user.email;

    state.observation.$save();
  },

  PARTIAL_UPDATE_DRAFT_OBSERVATION: (state, payload) => {
    if (!state.observation) return;
    forIn(payload, (value, key) => (state.observation[key] = value));
    state.observation.$save();
  },

  SET_MULTI_PERIMETER: (state, perimeter) => {
    const factory = new PerimeterFactory(perimeter);
    const observables = factory.toAmosMulti().getUuids();
    state.observation.aosObservablesList = observables;
    state.observation.$save();
  },

  SET_SINGLE_PERIMETER: (state, perimeter) => {
    const factory = new PerimeterFactory(perimeter);
    const observables = factory.toAmosSingle().getUuids();
    state.observation.aosObservablesList = observables;
    state.observation.$save();
  },

  CHANGE_DEFAULT_CREATION_STATE: (state, newState) => {
    state.defaultCreationState = newState;
  },
};
