import ComponentAsset from "@/models/ComponentAsset";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import requestFactory from "../factory";
import { cloneDeep } from "lodash";
import { Struct } from "google-protobuf/google/protobuf/struct_pb";
import { AosObservable } from "@/models";
import { useRxdbCollection } from "@/composables/useRxdbCollection";

const client = socioGrpcClient.amos_back.aos.ComponentControllerPromiseClient;
const aosStub = socioGrpcClient.amos_back.aos;
const {
  amos_back: { aos: aosApi },
} = socioGrpcClient;
export class ComponentCharacteristics {
  constructor(data = {}, propertiesList = []) {
    this.uuid = data.uuid;
    this.typology = data.typology;
    this.label = data.label;
    this.quantity = data.quantity;
    this.theoricalLifespan = data.theoricalLifespan;
    this.installationRenewalDate = data.installationRenewalDate;
    this.length = data.length;
    this.width = data.width;
    this.height = data.height;
    this.surface = data.surface;
    this.volume = data.volume;
    this.weight = data.weight;
    this.volumicMass = data.volumicMass;
    this.observationComment = data.observationComment;
    this.code = data.code;
    this.description = data.description;
    this.properties = this.formatProperties(
      (propertiesList.length && propertiesList) || data.propertiesList
    );
  }
  formatProperties(properties = []) {
    if (!properties.length) return {};
    const formattedProperties = {};
    properties.forEach((i) => {
      if (i.value instanceof Struct) {
        formattedProperties[i.propertyDefinition] =
          i.value.toJavaScript().value;
      } else {
        formattedProperties[i.propertyDefinition] = i.value;
      }
    });
    return formattedProperties;
  }
}

const state = {
  componentData: new ComponentCharacteristics(),
  componentDataInitCopy: new ComponentCharacteristics(),
};

const getters = {};

const actions = {
  // MS - INFO - 24/07/24 - Caution ! ComponentAsset pulled on the app start
  // but no logic for upsert in rxdb if this action is recalled elsewhere
  fetchComponents: async (_, { metadata = {}, listAll = false }) => {
    const request = new socioGrpcClient.amos_back.aos.ComponentListRequest();
    const response = listAll
      ? await client.utils.listAllObjects(request, metadata)
      : await client.list(request, metadata);

    const data = listAll ? response : response.toObject().resultsList;
    await ComponentAsset.insert({
      data,
    });
    return data;
  },
  async retrieveCharacteristics(_, assetUuid) {
    const request = new aosStub.ComponentRetrieveDetailsRequest();
    request.setUuid(assetUuid);
    const response = await client.retrieveDetails(request, {});
    return response.toObject();
  },

  async setActors(_, actors) {
    let request = socioGrpcClient.javascriptToRequest(
      aosApi.AosActorsM2MInputRequest,
      {}
    );
    request.setAosObservable(actors.aosObservable);
    request.setContributorsList(
      actors.contributors.map((contributor) => {
        return socioGrpcClient.javascriptToRequest(
          aosApi.ContributorActorInputRequest,
          contributor
        );
      })
    );
    await client.setActors(request, {});
  },

  async partialUpdateCharacteristics(
    { rootGetters },
    { structureName, aosItemData }
  ) {
    const asset = rootGetters["asset/retrieveAssetFromAosItem"](
      structureName,
      aosItemData.uuid
    );

    // we update the data in rxdb to trigger a push via replicationCollection
    // this allow offline update
    const [aosObservableDep] = AosObservable.query()
      .where("assetUuid", asset?.uuid)
      .where("createdOrUpdatedOffline", true)
      .get();

    const { execOnCollection: componentAssetCollection } =
      useRxdbCollection("component_asset");
    const componentAssetRxdbDocumentsMap = await componentAssetCollection((c) =>
      c.findByIds([asset?.uuid]).exec()
    );

    const componentAssetDocument = componentAssetRxdbDocumentsMap.get(
      asset?.uuid
    );

    await componentAssetDocument.incrementalUpdate({
      $set: {
        code: aosItemData.code,
        observationComment: aosItemData.observationComment,
        description: aosItemData.description,
        createdOrUpdatedOffline: true,
        offlineAosObservableUuidDependency: aosObservableDep?.uuid ?? "",
      },
    });
    ComponentAsset.update({
      where: asset.uuid,
      data: {
        observationComment: aosItemData.observationComment,
        code: aosItemData.code,
        description: aosItemData.description,
      },
    });
  },

  updateCode: async (_, { uuid, code }) => {
    const request =
      new socioGrpcClient.amos_back.aos.ComponentUpdateCodeRequest();
    request.setUuid(uuid);
    request.setCode(code);
    ComponentAsset.update({
      where: uuid,
      data: { code },
    });

    return (await client.updateCode(request, {})).toObject();
  },

  duplicate: requestFactory.actions.duplicateFactory(
    client,
    socioGrpcClient.amos_back.aos.DuplicationRequest,
    "ComponentAsset"
  ),
  move: requestFactory.actions.moveFactory(
    client,
    socioGrpcClient.amos_back.aos.MoveRequest,
    "ComponentAsset"
  ),
  delete: requestFactory.actions.deleteFactory(
    client,
    socioGrpcClient.amos_back.aos.DeleteRequest,
    "ComponentAsset"
  ),
};

const mutations = {
  SET_COMPONENT_DATA: (state, data) => {
    state.componentData = data;
  },
  SET_COMPONENT_DATA_COPY: (state) => {
    state.componentDataInitCopy = cloneDeep(state.componentData);
  },
  SET_COMPONENT_DATA_TO_SAVE: (state, data) => {
    state.componentData = Object.assign(
      new ComponentCharacteristics(),
      state.componentData,
      new ComponentCharacteristics(data)
    );
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
