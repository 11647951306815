import socioGrpcClient from "@/setup/socioGrpcClient";
import { setRequestFilters } from "@/utils/request";

const state = {
  amosKpis: {
    aosItemCount: 0,
    aosItemObservationCount: 0,
    dispositionObservationCount: 0,
    docreviewObservationCount: 0,
    nonEmptyProjectCount: 0,
    numberOfActiveUsers: 0,
    totalObservationsCount: 0,
    generatedReportsCount: 0,
    errorReportsCount: 0,
  },
};

const getters = {
  getKpis: (state) => state.amosKpis,
};

const actions = {
  async fetchManagementKpis({ commit }) {
    const amosRequest =
      new socioGrpcClient.amos_back.amos_admin.AmosKpiRequest();
    const amosResponse =
      socioGrpcClient.amos_back.amos_admin.KpiControllerPromiseClient.getAmosKpi(
        amosRequest,
        {}
      );

    const reportRequest =
      new socioGrpcClient.report_generator.reports.ReportGetStatsReportRelatedCountRequest();

    reportRequest.setServiceId("amos");

    const reportResponse =
      socioGrpcClient.report_generator.reports.ReportControllerPromiseClient.getStatsReportRelatedCount(
        reportRequest,
        {}
      );

    const [amosData, reportStruct] = await Promise.all([
      amosResponse,
      reportResponse,
    ]);

    const reportStats = reportStruct.getStats().toJavaScript();
    const projectIds = reportStats.sid_successfull_reports_data.map(
      (item) => item.object_id_as_str
    );
    let amosProjectsRequest =
      new socioGrpcClient.amos_back.projects.ProjectListRequest();

    amosProjectsRequest = setRequestFilters({
      request: amosProjectsRequest,
      filters: { uuid__in: [...new Set(projectIds)].join(",") },
    });

    const projectsResponse =
      await socioGrpcClient.amos_back.projects.ProjectControllerPromiseClient.utils.listAllObjects(
        amosProjectsRequest,
        {}
      );

    const projectsMap = projectsResponse.reduce((acc, project) => {
      acc[project.uuid] = project;
      return acc;
    }, {});

    const reportsData = reportStats.sid_successfull_reports_data.map(
      (reportItem) => {
        const project = projectsMap[reportItem.object_id_as_str];
        return {
          ...reportItem,
          caseNumber: project?.caseNumber,
          mission: project?.mission,
          projectName: project?.projectName,
        };
      }
    );
    commit("SET_KPIS", {
      ...amosData.toObject(),
      totalReportsCount: reportStats.sid_total_reports_count,
      generatedReportsCount: reportStats.sid_successfull_reports_count,
      errorReportsCount: reportStats.sid_failed_reports_count,
      reportsData,
    });
  },
};

const mutations = {
  SET_KPIS(state, amosKpis) {
    state.amosKpis = amosKpis;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
