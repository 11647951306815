import socioGrpcClient from "@/setup/socioGrpcClient";
import { ActorTypology } from "@/models";

const {
  amos_back: { amos_admin: amosAdminApi },
} = socioGrpcClient;

const state = {};

const actions = {
  async fetchActorTypologies() {
    const metadata = {
      filters: JSON.stringify({}),
      pagination: JSON.stringify({
        page_size: 500,
      }),
    };
    const request = new amosAdminApi.ActorTypologyListRequest();
    const response =
      await amosAdminApi.ActorTypologyControllerPromiseClient.list(
        request,
        metadata
      );

    await ActorTypology.insertOrUpdate({
      data: response.toObject().resultsList,
    });
  },
};
const mutations = {};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
